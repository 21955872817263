.info-panel-container {
  margin-bottom: 1rem;

  .info-panel {
    cursor: help;
    padding: 5px 15px 5px 5px;
    border-radius: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    font-size: 0.75rem;
    color: #ffffff;
    opacity: 1;
    margin-bottom: .5rem;

    p {
      line-height: 1rem;
    }

    .info-icon {
      width: 43px;
      height: 43px;
      margin-right: 22px;
      border-radius: 36px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      border: 1px solid #ffffff;
    }
  }
}



.scenarios-item {
  width: calc(33.3% - .8rem);
  margin-right: 1.2rem;
  margin-bottom: 1.2rem;
  text-align: left;
  cursor: pointer;
  position: relative;
  height: 320px;
  overflow: hidden;
  padding-top: 180px !important;
  background: $white;

  &.scenario__select {
    height: 150px;
    padding: 0 !important;

    img {
      width: 50%;
      height: auto;
    }

    h2 {
      font-size: 1.2rem !important;
    }

    h3 {

      margin-left: 1rem;
      margin-right: 1rem;
      font-size: .9rem;
      font-weight: 500;
    }
  }

  .scenario__seen {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(0, 0, 0, .2);
    z-index: 1;

    .mdi {
      color: #fff;
      font-size: 1.2rem;
      line-height: 2.5rem;
    }
  }

  &:nth-of-type(3n) {
    margin-right: 0;
  }

  &.selected {
    box-shadow: 0 0 0 5px $color-secondary;
  }

  .scenario__selected__bg {
    position: absolute;
    opacity: .3;
    z-index: 3;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: $color-secondary;
    padding: 2rem;
  }

  .scenario__bg {
    opacity: 0;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .8);
    padding: 2rem;
    transition: all .2s;

    p {
      color: $white;
      font-size: 1rem;
      text-align: center;
    }
  }

  &:hover {

    .scenario__bg {
      opacity: 2;
    }

  }

  .scenarios__img {
    height: 260px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .scenarios__name {
    height: 100px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 220px;
    overflow: hidden;
    padding: 0 1.5rem;

    h2 {
      font-size: 1.2rem;
      font-weight: 700;
      color: $color-black;
    }
  }

  p {
    color: $color-gray;
    line-height: 1.6rem;
  }
}


@media (max-width: 1024px) {

  .modal {

    .scenarios-item {

      &.scenario__select {

        h2 {
          font-size: .9rem !important;
          line-height: 1rem !important;

        }
      }
    }
  }

  .scenarios-item {
    width: calc(50% - .5rem);
    margin-right: 1rem !important;
    margin-bottom: 1rem;
    padding: 1rem;
    height: 160px;

    .scenarios__img {
      height: 120px;
    }

    .scenarios__name {
      top: unset;
      bottom: 0;
      padding: 0 .5rem;

      h2 {
        font-size: .9rem;
      }
    }

    &:nth-of-type(3n) {
      margin-right: unset;
    }

    &:nth-of-type(2n) {
      margin-right: 0 !important;
    }

    h2 {
      font-size: 1.6rem;
    }

    h3 {
      font-size: .8rem !important;
    }

    p {
      font-size: .8rem !important;
      line-height: 1rem;
    }
  }
}

@keyframes scenario-bg-fade {

  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }

}
.settings-item {
  width: calc(33.3% - .8rem);
  margin-right: 1.2rem;
  margin-bottom: 1.2rem;
  text-align: left;
  position: relative;
  overflow: hidden;
  //border: 1px solid rgba(0, 0, 0, .1) !important;

  .input__item {
    box-shadow: 1px 1px 2px rgba(0, 0, 0, .1);
  }

  &:nth-of-type(3n) {
    margin-right: 0;
  }

  h2 {
    font-size: 1.6rem;
    font-weight: 700;
    color: $color-black;
  }

  p {
    color: $color-gray;
    line-height: 1.6rem;
  }
}

@media (max-width: 640px) {

  .card-container {
    margin-bottom: 3rem;

    .settings-item {
      width: 100%;
      margin-right: 0;
      margin-bottom: 1rem;
      padding: 1rem;

      h2 {
        font-size: 1.6rem;
      }

      p {
        font-size: .8rem;
        line-height: 1.2rem;
      }
    }
  }
}

@keyframes scenario-bg-fade {

  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }

}
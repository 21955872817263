.chat-container {
  display: flex;
  height: calc(100vh - 65px);
  background-image: url("/img/bg-admin.jpg");
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  //padding-top: 2rem;

  .chat-channels {
    width: 400px;
    /*margin-right: 1.5rem;*/
    padding: 0 1.5rem;
    background: rgba(100, 100, 100, .1);
    padding-top: 2rem;
    font-size: 1rem;
    font-weight: 600;

    .chat__channel {
      padding: .8rem 1.4rem;
      border-radius: 10px;
      cursor: pointer;
      position: relative;

      &.active {
        background: $color-secondary;
        color: $color-white;
      }
    }
  }

  .chat-discussion {
    flex-grow: 1;
    position: relative;

    .discussion-container {
      height: 100%;

      .btn--older {
        position: absolute;
        top: 10px;
        left: calc(50% - 75px);
        background: rgba(255, 255, 255, .6);
        border-radius: 10px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, .2);
        padding: .2rem 0;
        width: 150px;
        font-size: .8rem;
        font-weight: 600;
      }

      &__inner {
        overflow: auto;
        height: calc(100% - 60px);
        display: flex;
        flex-direction: column;

        .discussion__message {
          align-self: flex-start;
          //margin-bottom: 1rem;
          padding: .6rem 2rem;

          .message__thumb {
            max-height: 100px;
            max-width: 200px;
          }

          .message__desc {
            font-size: .8rem;
          }

          &.author {
            align-self: flex-end;

            .message {
              background: $color-primary;
              color: $color-white;

              a {
                color: $color-white;
              }
            }
          }

          .message {
            background: $color-white;
            color: $color-black;
            box-shadow: 2px 2px 6px rgba(0, 0, 0, .15);
            padding: .6rem;
            border-radius: 10px;

            a {
              color: $color-black;
              text-decoration: none;
            }
          }

          .teacher {
            background: $color-brown;
            color: $color-white;

            a {
              color: $color-white;
            }
          }

          &__details {
            display: flex;
            padding-top: .2rem;
          }

          &__author {
            font-size: .8rem;
            color: $color-gray;
            margin-right: .4rem;
          }

          &__timestamp {
            font-size: .8rem;
            color: $color-gray;
          }
        }
      }
    }

    .discussion-submit {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60px;
      background: rgba(255, 255, 255, .7);

      .input-group {

        input {
          width: 100%;
          height: 60px;
          background: $color-white;
          border: none;
          outline: none;
          padding: 0 6rem 0 1.6rem;
          box-sizing: border-box;
          font-family: $font-primary;
        }

        button {
          position: absolute;
          right: 10px;
          top: 12px;

        }
      }

      label {
        position: absolute;
        top: 16px;
        right: 100px;
        font-size: 1.6rem;
        cursor: pointer;
      }

      &__file {
        position: absolute;
        right: 150px;
        top: 15px;
        background: $color-white;
        border: 1px solid $color-bright;
        padding: 0.4rem 0.8rem;
        border-radius: 50px;
        display: flex;
        align-items: center;

        .mdi {
          color: $color-danger;
          margin-left: 0.4rem;
          font-size: 0.8rem;
          cursor: pointer;
        }
      }

    }
  }
}

@media (max-width: 640px) {

  .chat-container {
    height: calc(100vh - 45px);

    .chat-channels {
      width: 120px;
      padding: 0 .3rem;
      padding-top: 1rem;
      font-size: .6rem;

      .chat__channel {
        padding: .4rem .8rem;
      }
    }

    .chat-discussion {

      .discussion-container {

        .btn--older {
          position: absolute;
          top: 5px;
          left: calc(50% - 50px);
          padding: .2rem 0;
          width: 100px;
          font-size: .7rem;
        }

        &__inner {
          height: calc(100% - 50px);

          .discussion__message {
            padding: .4rem 1rem;

            .message {
              padding: .4rem;
              font-size: .7rem;
            }

            &__author {
              font-size: .7rem;
              margin-right: .2rem;
            }

            &__timestamp {
              font-size: .7rem;
            }
          }
        }
      }

      .discussion-submit {
        height: 50px;

        .input-group {

          input {
            height: 50px;
            padding: 0 4.6rem 0 1rem;
          }

          button {
            top: 10px;
          }
        }
      }
    }
  }
}
.bmc-container {
  position: fixed;
  top: 65px;
  left: 0;
  height: calc(100% - 65px);
  width: 100%;
  display: grid;
  grid-template-areas: "partners partners activities activities propositions propositions relationships relationships segments segments" "partners partners resources resources propositions propositions channels channels segments segments" "cost cost cost cost cost streams streams streams streams streams";
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  background: $white;
  padding: 15px;

  .bmc__item {
    background: #eee;
    color: $black;
    border-radius: 10px;
    padding: 20px;
    position: relative;

    .bmc__name {
      position: absolute;
      left: 15px;
      top: 15px;

      h2 {
        font-weight: 700;
        font-size: 1.2rem;
        margin: 0;
      }

    }

    .bmc__icon {

      img {
        height: 55px;
      }
    }

    &.active {
      background: $color-secondary;
    }
  }

  .partners {
    grid-area: partners;
  }

  .activities {
    grid-area: activities;
  }

  .resources {
    grid-area: resources;
  }

  .propositions {
    grid-area: propositions;
  }

  .relationships {
    grid-area: relationships;
  }

  .channels {
    grid-area: channels;
  }

  .segments {
    grid-area: segments;
  }

  .cost {
    grid-area: cost;
  }

  .streams {
    grid-area: streams;
  }
}

.sbmc-container {
  position: fixed;
  top: 65px;
  left: 0;
  height: calc(100% - 65px);
  width: 100%;
  display: grid;
  grid-template-areas: "resources resources activities activities intervention intervention segments segments propositions propositions" "stakeholders stakeholders activities activities channels channels segments segments propositions propositions" "cost cost cost cost surplus surplus revenue revenue revenue revenue";
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  background: $white;
  padding: 15px;

  .sbmc__item {
    background: #eee;
    color: $black;
    border-radius: 10px;
    padding: 20px;
    position: relative;

    .sbmc__name {
      position: absolute;
      left: 15px;
      top: 15px;

      h2 {
        font-weight: 700;
        font-size: 1.2rem;
        margin: 0;
      }

    }

    .sbmc__icon {

      img {
        height: 55px;
      }
    }

    .sbmc__desc {
      color: $black;
      position: absolute;
      left: 30px;
      bottom: 30px;
      width: calc(100% - 60px);

      p {
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        padding-bottom: .2rem;
        margin-bottom: .2rem;
        font-size: .8rem !important;
        opacity: .6;
        font-style: italic;

        &:last-of-type {
          border-bottom: none;
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }
    }

    &.active {
      background: $color-secondary;
      color: $white;
    }
  }

  .resources {
    grid-area: resources;
  }

  .stakeholders {
    grid-area: stakeholders;
  }

  .activities {
    grid-area: activities;
  }

  .intervention {
    grid-area: intervention;
  }

  .channels {
    grid-area: channels;
  }

  .segments {
    grid-area: segments;
  }

  .propositions {
    grid-area: propositions;
  }

  .cost {
    grid-area: cost;
  }

  .surplus {
    grid-area: surplus;
  }

  .revenue {
    grid-area: revenue;
  }
}
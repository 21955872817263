.quiz-slider {
  position: fixed;
  right: 0px;
  top: 65px;
  background: #f2f2f2;
  height: calc(100vh - 65px);
  width: 60px;

  .slider__top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
  }

  .slider__bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
  }

  .slider__time {
    padding: 10px;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1;

    .time__container {
      line-height: 1rem;
    }

    span {
      font-size: .8rem;
      font-weight: 400;
    }
  }

  .slider__center {
    position: absolute;
    top: 70px;
    width: 100%;
    left: 0;
    height: calc(100% - 140px);

    .slider__points {
      position: absolute;
      right: 80px;
      width: 80px;
      background: $white;
      padding: .4rem;
      border-radius: 4px;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, .15);
      margin-top: -1rem;
      height: 37px;

      span {
        font-size: 1.2rem;
        font-weight: 600;
      }

      img {
        width: 20px;
        height: 24px;
      }

      &:after {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        top: 11px;
        right: -8px;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid $color-white;
      }
    }

    .slider__progress {
      position: relative;
      margin-left: 22px;

      &.vertical {
        height: 100%;
        background: $color-white;
        width: 16px;
        border-radius: 10px;
        position: relative;

        .progress__fill {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          border-radius: 10px;
        }
      }

      &.success {
        background: $color-white;

        .progress__fill {
          background: $color-secondary;
        }
      }

      &.warning {
        background: $color-white;

        .progress__fill {
          background: $orange-500;
        }
      }

      &.danger {
        background: $color-white;

        .progress__fill {
          background: $red-500;
        }
      }
    }
  }
}

@media (max-width: 640px) {

  .quiz-slider {
    width: 36px;

    .slider__time {
      padding: 5px;
      font-size: 1.2rem;

      span {
        font-size: .8rem;
        font-weight: 400;
      }
    }

    .slider__center {

      .slider__points {
        right: 50px;

        span {
          font-size: 1.2rem;
          font-weight: 600;
        }

        img {
          width: 20px;
          height: auto;
        }

        &:after {
          content: '';
          width: 0;
          height: 0;
          position: absolute;
          top: 11px;
          right: -8px;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          border-left: 8px solid $color-white;
        }
      }

      .slider__progress {
        position: relative;
        margin-left: 12px;

        &.vertical {
          width: 12px;
        }
      }
    }
  }
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 65px;
  background: $color-white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  z-index: 10;

  .navbar {
    max-width: 1200px;
    width: 96%;
    //margin-left: 2%;
    height: 100%;

    .nav-toggle {
      margin-top: 3px;

      button {

        img {
          width: 25px;
        }
      }
    }

    .nav-logo {
      padding: .5rem 0;

      img {
        height: auto;
        width: 120px;
      }
    }

    .nav-info {
      padding: 3px;
      border-radius: 3px;
      border: 1px solid #f2f2f2;
      background-image: linear-gradient(
                      to top,
                      #EBEFF0,
                      #EBEFF0 50%,
                      #fff 50%,
                      #fff 100%
      );

      .nav-info__inner {
        border-radius: 3px;
        height: 36px;
        background-image: linear-gradient(
                        to top,
                        #BDBDBD,
                        #BDBDBD 50%,
                        #EEEEEE 50%,
                        #EEEEEE 100%
        );

        &.nav--supporters {
          background-image: linear-gradient(
                          to top,
                          #F9D34A,
                          #F9D34A 50%,
                          #F9E9AE 50%,
                          #F9E9AE 100%
          );

          .nav-info__right-inner {
            background: #F9E9AE !important;
          }
        }

        .nav-info__left {
          padding: 5px;
          position: relative;

          img {
            width: 20px;
            height: 20px;
          }

          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: calc(100% + 1px);
            width: 1px;
            height: 100%;
            background: #EEEEEE;
          }
        }

        .nav-info__right {
          padding: 5px 10px 5px 10px;

          img {
            width: 20px;
          }
        }

        .nav-info__right-right {
          padding: 5px 5px 5px 0;

          span {
            font-size: .8rem;
            font-weight: 800;
            color: $color-primary;
          }
        }

        .nav-info__left {
          border-right: 1px solid #aaa;
        }

        .nav-info__right-inner {
          padding: 3px 13px;
          border-radius: 2px;
          box-shadow: inset 1px 1px 1px 0 rgba(0, 0, 0, .2);
          background: #ddd;
          font-weight: 700;

          &.progress {
            padding: 3px;
          }

          .nav-info__progress {
            width: 100px;
            height: 20px;
            background-color: $color-secondary;
            display: block;
            border-radius: 2px;
          }
        }

        .nav-info__addcoins {
          padding: 0;
        }
      }
    }

    .nav-sound {
      display: flex;

      button {
        background: transparent;
        padding: 1rem 0;
        margin-right: 1rem;

        img {
          height: 20px;
        }
      }
    }

    .nav-menu {

      .menu__item {
        justify-content: stretch;
        position: relative;

        & > a {
          padding: 0 1rem;
          border-right: 1px solid $color-bright;
          color: $color-dark;
          font-size: 1rem;
          text-decoration: none;
          transition: all .2s;

          &.hover {
            background: #fafafa;
          }

          img {
            height: 20px;
            width: 20px;

            &.nav-icon__s {
              width: 14px;
            }
          }

          .mdi {
            font-size: 1.6rem;
          }
        }

        &.dropdown {

          &.active {

            .dropdown-container {
              height: auto;
            }
          }

          &.inactive {

            .dropdown-container {
              height: 0 !important;
              border: none;
            }
          }

          .dropdown-container {
            position: absolute;
            top: 100%;
            right: 0;
            width: 220px;
            border: 1px solid $color-bright;
            overflow: hidden;
            border-radius: 0 0 10px 10px;

            &:after {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              width: 100%;
              //filter: blur(3px);
              background-color: rgba(255, 255, 255, .95);
              //opacity: .9;
              z-index: 1;
            }

            .dropdown__item {

              &:last-of-type {

                a {
                  border-bottom: 0;
                }
              }
            }

            a {
              width: 100%;
              padding: 1rem 1rem 1rem 3.5rem;
              display: block;
              color: $color-dark;
              border-bottom: 1px solid $color-bright;
              text-decoration: none;
              transition: all .2s;
              position: relative;
              z-index: 2;

              &:hover {
                background-color: #fafafa;
              }

              .mdi {
                position: absolute;
                left: 1rem;
                top: 1rem;
                font-size: 1.2rem;
              }
            }

            &.notifications {
              max-height: 320px;
              width: 320px;
              overflow: auto;

              .dropdown__item {
                background-color: rgba(255, 255, 255, .95);
              }

              a {
                padding-left: 1rem;
                font-size: .8rem;

                img {
                  opacity: .3;
                  width: 30px;
                  height: auto;
                  margin-right: 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {

  .header {
    height: 45px;

    .navbar {
      //padding: 0 1rem;

      .nav-toggle {

        button {

          img {
            width: 18px;
          }
        }
      }

      .nav-logo {

        img {
          height: 100%;
          width: auto;
        }
      }

      .nav-info {
        transform: scale(.8);
        margin-right: -1rem;
      }

      .nav-sound {

        a {
          padding: .7rem 0;

          img {
            height: 22px;
          }
        }
      }

      .nav-menu {

        .menu__item > a {
          padding: 0 .5rem;

          img {
            height: 14px;
          }
        }
      }

    }
  }
}

@media (max-width: 640px) {

  .navbar {

    .nav-left {
      width: 50%;
    }

    .nav-menu {
      width: 50%;

      img {
        //height: 15px !important;
      }

      .menu__item {

        &:last-of-type {

          a {
            border-right: 0 !important;
          }
        }
      }

      .dropdown-open__icon {
        display: none;
      }
    }

    .dropdown-container {
      position: fixed !important;
      top: 45px !important;
      left: 0 !important;
      width: 100% !important;
      border-left: none !important;
      border-right: none !important;
      border-top-width: 1px !important;
      border-bottom-width: 1px !important;
      border-radius: 0 !important;

      &:after {
        background: $white !important;
      }
    }
  }
}
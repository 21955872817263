button {
  border: none;
  cursor: pointer;
  padding: 0;
  background: transparent;
  font-size: inherit;

  &:focus {
    outline: none;
    border: none;
  }
}

.btn {
  border-radius: 30px;
  border: none;
  transition: all .2s;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  text-align: center;

  &.btn--share {

    img {
      height: 25px;
    }
  }

  &.left {
    text-align: left;
  }

  &:focus {
    outline: none;
    border: none;
  }

  &.btn--prev {
    z-index: 2;
    position: fixed;
    top: calc(50% - 30px);
    left: 30px;
    width: 75px;
    height: 75px;
    display: flex;
    box-shadow: 0 0 0 10px rgba(0, 0, 0, .1);

    .mdi {
      font-size: 2rem;
    }

    .text {
      margin-top: -5px;
      font-weight: 700;
      opacity: .7;
      font-size: .8rem;
    }
  }

  &.btn--next {
    z-index: 2;
    position: fixed;
    top: calc(50% - 30px);
    right: 30px;
    width: 75px;
    height: 75px;
    display: flex;
    box-shadow: 0 0 0 10px rgba(0, 0, 0, .1);

    .mdi {
      font-size: 2rem;
    }

    .text {
      margin-top: -5px;
      font-weight: 700;
      opacity: .9;
      font-size: .8rem;
    }
  }

  &.btn--0 {
    font-size: .8rem;
    padding: 0;
  }

  &.btn--0s {
    font-size: 1rem;
    padding: 0;
  }

  &.btn--s {
    font-size: .9rem;
    padding: .5rem 1.2rem;
    font-weight: 600;

    img {
      height: 15px;
    }
  }

  &.btn--m {
    font-size: 1.2rem;
    padding: .8rem 2.4rem;
    font-weight: 700;
  }

  &.btn--l {
    font-size: 1.3rem;
    padding: .5rem 2.4rem;
    font-weight: 700;
  }

  &.btn--primary {
    color: $color-white;
    background: $color-primary;
  }

  &.btn--secondary {
    color: $color-white;
    background: $color-secondary;

    &:hover {
      background: darken($color-secondary, 10%);
    }

    &.dark {
      color: $color-black;
    }
  }

  &.btn--success {
    color: $color-white;
    background: $color-success;

    &:hover {
      background: darken($color-success, 3%);
    }
  }

  &.btn--danger {
    color: $color-white;
    background: $color-danger;

    &:hover {
      background: darken($color-danger, 3%);
    }
  }

  &.btn--warning {
    color: $color-white;
    background: $color-warning;

    &:hover {
      background: darken($color-warning, 3%);
    }

    &.dark {
      color: $color-black;
    }
  }

  &.btn--white {
    color: $color-primary;
    background: $color-white;
    border: 1px solid $color-bright;

    &.nb {
      border: none;
    }
  }

  &.btn--text {
    color: $color-dark;
    background: transparent;
    text-decoration: none;
    border-radius: 0;

    &.featured {
      color: $color-secondary;
    }

    &.danger {
      color: $red-500;
    }

    &.white {
      color: $white;
    }

    &.light {
      color: $color-gray;
    }
  }

  &.btn--shadow {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);

    &:hover {
      box-shadow: none;
    }
  }

  &.btn--menu {
    position: absolute;
    right: 1rem;
    top: 1rem;
    padding: 2rem 1.6rem;
    background: $color-dark;

    &:hover {
      background: darken($color-dark, 3%);
    }
  }
}

.btn-container {
  width: 100%;
}

button {
  font-family: $font-primary !important;
}

@media (max-width: 1024px) {

  .btn {

    &.btn--prev {
      top: calc(100% - 75px);
      width: 45px;
      height: 45px;
      box-shadow: 0 0 0 5px rgba(0, 0, 0, .1);

      .mdi {
        font-size: 1.3rem;
      }

      .text {
        bottom: 5px;
        left: 13px;
        opacity: .7;
        font-size: .6rem;
      }
    }

    &.btn--next {
      top: calc(100% - 75px);
      width: 45px;
      height: 45px;
      box-shadow: 0 0 0 5px rgba(0, 0, 0, .1);

      .mdi {
        font-size: 1.3rem;
      }

      .text {
        bottom: 5px;
        left: 12px;
        opacity: .7;
        font-size: .6rem;
      }
    }

    &.btn--s {
      font-size: .7rem;
    }

    &.btn--m {
      font-size: .9rem;
      padding: .5rem 1rem;
    }

    &.btn--l {
      font-size: 1.1rem;
      padding: .8rem 1.6rem;
    }
  }
}
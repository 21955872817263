.dropzone {
  width: 100%;
  background-color: $color-white;;
  //border: 2px dashed $gray-300;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, .15);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  padding: 2rem;

  img {
    width: 40px;
    height: auto;
    color: $gray-300;
  }
}

.FileInput {
  display: none;
}
.sidebar-container {
  position: fixed;
  top: 85px;
  bottom: 20px;
  border-radius: 0 20px 20px 0;
  left: -140px;
  width: 140px;
  background: $white;
  transition: all .2s;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, .15);

  &.active {
    left: 0;
    z-index: 1000;
  }

  .sidebar__item {
    height: calc(100% / 4);
    border-bottom: 1px solid $sidebar-border;
    color: $gray-600;
    text-decoration: none;
    font-size: .9rem;
    overflow: hidden;
    transition: all .2s;
    position: relative;

    &:hover {
      background: #fafafa;
    }

    &:last-of-type {
      border: none;
      border-radius: 0 0 20px 0;
    }

    &:first-of-type {
      border-radius: 0 20px 0 0;
    }

    &.active {

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 8px;
        height: 100%;
        background-color: $color-secondary;
      }
    }

    img {
      height: 45px;
    }
  }
}

@media (max-width: 1024px) {

  .sidebar-container {
    height: 400px;
    width: 120px;

    &.active {
      left: 0;
    }

    .sidebar__item {
      height: 100px;

      img {
        height: 30px;
      }
    }
  }
}
#flashes {

  .alert {
    position: fixed;
    width: 100%;
    height: 65px;
    top: 0;
    left: 0;
    z-index: 99;
    animation: flash-animation 5s;
    animation-fill-mode: forwards;
    opacity: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


.alert {
  padding: 1rem 2rem;
  font-size: 1rem;
  border-radius: 50px;
  font-weight: 800;
  display: block;

  &.s {
    padding: .2rem .4rem;
    font-size: .7rem;
    border-radius: 4px;
  }

  ul {
    list-style: none;
  }

  &.alert--default {
    background: $color-black;
    color: $color-white;
  }

  &.alert--danger {
    background: $color-danger;
    color: $color-white;
  }

  &.alert--success {
    background: $color-success;
    color: $color-white;
  }

  &.alert--warning {
    background: $color-warning;
    color: $color-white;
  }
}

@keyframes flash-animation {
  0% {
    opacity: 0;
    top: -2rem
  }
  15% {
    opacity: 1;
    top: 0;
  }

  85% {
    opacity: 1;
    top: 0;
  }

  100% {
    opacity: 0;
    top: -2rem;
  }
}

@media (max-width: 640px) {

  #flashes {

    .alert {
      position: fixed;
      text-align: center;
      width: calc(100% - 2rem);
      top: 1rem;
      left: 1rem;
      z-index: 99;
      animation: flash-animation 5s;
      animation-fill-mode: forwards;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
      opacity: 1;
    }
  }

  .alert {
    padding: .5rem 1rem;
    font-size: .8rem;
  }
}
.img-bg-container {
  position: fixed;
  top: 65px;
  width: 100%;
  height: calc(100vh - 65px);
  display: flex;
  background: $color-black;

  &.top {
    top: 0;
    bottom: auto;
  }
}

.office-container {
  display: inline-block;
  margin: auto;
  position: relative;
}

.office-diplomas {
  position: absolute;
  top: 13%;
  left: 20%;
  width: 43%;
  z-index: 2;

  .diploma {
    margin-right: 1rem;
    margin-bottom: 1rem;
    width: calc(20% - .8rem);
    cursor: pointer;

    &:nth-of-type(5n) {
      margin-right: 0;
    }
  }
}

.office-badges {
  width: 15%;
  position: absolute;
  top: 12%;
  right: 20%;
  z-index: 2;

  .badge {
    width: 50%;
    margin: -5px;
    cursor: pointer;

    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }
}

.office--img {
  position: relative;
  height: calc(100vh - 65px);
  z-index: -2;

  &.wall {
    z-index: 0;
  }
}

.office--img-absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: auto;
  height: 100%;
  z-index: 1;
  //width: 100%;
  //object-fit: cover;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 150px;
    height: 100%;
  }

  &.theory {

    @media (max-width: 768px) {
      position: fixed;
    }

  }
}

.supporters {
  bottom: 0;
  top: auto;
  width: 1250px;
  height: 500px;
  right: auto;
  left: calc(50% - 670px);
  z-index: 0;
  margin: 0;
}

.sbmc-download {
  position: absolute;
  left: 20%;
  top: 30%;
  z-index: 3;
  background: transparent;
  width: 20%;
  cursor: pointer;

  img {
    width: 100%;
  }
}

@media (max-width: 1024px) {

  .office--img {
    height: calc(100vh - 45px);
  }

  .img-bg-container {
    top: 45px;
    overflow: auto;
    height: calc(100vh - 45px);
  }

  .supporters {
    bottom: unset;
    top: 200px;
    width: 100%;
    height: auto;
    right: auto;
    left: 0;
  }
}

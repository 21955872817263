html {
  font-size: 16px;

  body {
    font-size: 1rem;
    font-family: $font-primary;
    background: #fafafa;
    overflow: auto;
    color: $color-black;

    h1 {
      font-family: $font-secondary;
      font-weight: normal;
    }

    p {
      line-height: 2rem;
    }

    .list-canvas {
      list-style: none;

      li {
        cursor: pointer;
        font-weight: bold;
      }
    }

    .mdi {
      vertical-align: middle;
    }

    .body__bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    #root {
      height: 100%;
    }

    .wrapper {
      position: relative;

      .wrapper__login {
        max-width: 1200px;
        width: 100%;
        background-color: transparent;
        margin-top: 65px;
        margin-bottom: 50px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        height: calc(100vh - 115px - 100px);

        .login__img {
          z-index: 0;
          margin-left: -50px;
          width: calc(100% + 50px);
        }
      }

      .wrapper__login-bg {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: url("/img/login/login-bg.svg");
        background-size: cover;
        z-index: -1;
      }

      .wrapper__inner {
        min-height: calc(100% - 3rem);
        height: auto;
        z-index: 1;
        position: relative;
        padding-bottom: 3rem;
        max-width: 80vw;

        &:before {
          content: "";
          height: 100%;
          width: 1rem;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          background: $color-primary;
        }
      }

      .wrapper__content, .wrapper__dashboard {
        max-width: 1200px;
        width: 96%;
        background-color: transparent;
        margin-top: 65px;
        margin-bottom: 50px;
        margin-left: auto;
        margin-right: auto;

        .dashboard__avatar {
          position: fixed;
          right: 0;
          top: 65px;
          z-index: -1;
          width: 40vw;
        }

        .dashboard__intro {
          min-width: 200px;
          border-radius: 50px;
          height: 50px;
          padding: 0 1rem;
          box-shadow: 5px 5px 15px rgba(0, 0, 0, .1);
          transition: all .2s;
          position: relative;
          z-index: 1;
          font-size: 1rem;
          font-weight: 700;

          &.blue {
            background: $color-secondary;
            color: $color-white;

            &:hover {
              background: darken($color-secondary, 5%);
            }
          }

          &.white {
            background: $color-white;
            color: $gray-800;

            &:hover {
              background: darken($color-white, 2%);
            }
          }

          &.animate {

            &:before {
              content: "";
              display: inline-block;
              height: 100%;
              width: 100%;
              border-radius: 100px;
              position: absolute;
              top: 0;
              left: 0;
              z-index: -1;
              transition: all .4s;
              background: $color-secondary;
              opacity: 0;
              animation: intro-pulse 2s infinite ease-out;
            }

            @keyframes intro-pulse {

              0% {
                transform: scaleX(.1) scaleY(.1);
                opacity: 0;
              }

              50% {
                opacity: 1;
              }
              100% {
                transform: scaleX(1.4) scaleY(1.5);
                opacity: 0;
              }
            }
          }

          .play-icon {
            width: 30px;
            height: 30px;
            margin-top: auto;
            margin-bottom: auto;
          }
        }

        .btn-social {
          border-radius: 50px;
          height: 50px;
          padding: .8rem 1rem;
          box-shadow: 5px 5px 15px rgba(0, 0, 0, .1);
          transition: all .2s;
          background-color: $color-white;
          z-index: 1;
          font-size: 1rem;
          font-weight: 700;

          a {

            img {
              height: 100%;
              width: 24px;
            }
          }
        }

        &.wrapper__cs {
          height: calc(100vh - 115px);

          .cs--bg {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $color-brown;
            z-index: -1;
          }

          h4 {
            font-size: 2rem;
          }

          p {
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.5rem;
          }
        }
      }

      .wrapper__dashboard {
        padding-left: 0;

        .dashboard__subheading {
          font-family: $font-secondary;
          color: $color-secondary;
          font-size: 1.8rem;
        }
      }
    }
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {

  .wrapper__dashboard {
    padding-left: 10rem !important;
  }
}


@media (max-width: 1024px) {

  .wrapper {
    height: auto !important;

    .wrapper__dashboard, .wrapper__login, .wrapper__content {
      padding: 1rem 0 !important;
      margin-bottom: 0 !important;
      margin-top: 45px !important;

      .dashboard__intro {
        min-width: 100px !important;
        height: 40px !important;
        font-size: .8rem !important;
        padding: 0 .7rem !important;

        img {
          width: 20px !important;
          height: 20px !important;
        }
      }
    }

    .wrapper__login {
      height: 100% !important;
      margin-top: 0 !important;
    }
  }
}

@media (max-width: 640px) {

  .wrapper__login {
    margin-top: 15px !important;
    margin-bottom: 0 !important;

    .login__img {
      margin-left: 0 !important;
      width: 100% !important;
    }
  }

  .dashboard__avatar {
    display: none;
  }

  .wrapper__cs {
    height: calc(100vh - 65px) !important;

    h4 {
      font-size: 1.2rem !important;
    }

    p {
      font-size: .9rem !important;
      line-height: 1.2rem !important;
    }
  }
}
@import "variables";

.modal-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  display: block;
}

.modal-open {
  overflow-y: hidden;
}

.backdrop {
  position: fixed;
  z-index: 1049;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  animation: modal-backdrop-animation .5s;
  animation-fill-mode: forwards;

  &.danger {
    background: rgba(243, 224, 224, .97);
  }

  &.success {
    background: rgba(217, 246, 229, .97);
  }

  &.warning {
    background: rgba(249, 231, 213, .97);
  }

  &.info {
    background: rgba(100, 100, 100, .7);
  }
}

.modal {
  margin: 0 auto;
  background: $white;
  border-radius: 10px;
  z-index: 13;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, .2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0;
  animation: modal-content-animation .5s;
  animation-fill-mode: forwards;
  animation-delay: .15s;
  overflow: auto;
  position: relative;

  &.modal__office, &.modal__unlock-level {
    background: url("/img/office/office-modal_bg.png");
    background-size: cover;
    background-color: $white;
    overflow: hidden;

    .modal__body {
      height: 500px;
      position: relative;

      .img__right {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }

    h2 {
      color: $white;
      font-family: $font-secondary;
      font-size: 2.8rem !important;
      font-weight: 400;
      line-height: 3.4rem;
    }

    p {
      color: $white;
      font-size: 1.4rem !important;
    }
  }

  &.modal__diploma {
    background: url("/img/bg-modal_diploma.png");
    background-size: cover;
    background-color: $white;
    overflow: hidden;

    .modal__body {
      height: 500px;
      position: relative;

      .img__right {
        position: absolute;
        right: 50px;
        top: 50px;
      }
    }

    h2 {
      font-family: $font-secondary;
      font-size: 2.8rem !important;
      font-weight: 400;
      line-height: 3.4rem;
    }

    p {
      font-size: 1.4rem !important;
    }
  }

  &.modal__outro {
    background: url("/img/bg-modal_outro.png");
    background-size: cover;
    background-color: $white;
    overflow: hidden;

    &.gray {
      background-image: url("/img/bg-modal_outro-gray.png");
    }

    &.blue {
      background-image: url("/img/bg-modal_outro-blue.png");
    }

    .modal__body {
      min-height: 600px;
      position: relative;

      .img__right {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 500px;
      }
    }

    h2 {
      font-family: $font-secondary;
      font-size: 2.8rem !important;
      font-weight: 400;
      line-height: 3.4rem;
      text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
      color: $white;
    }

    p {
      font-size: 1rem !important;

      &.text--m {
        font-size: 1.2rem !important;
      }
    }

    .text--supporters {
      font-size: 3.6rem !important;
      font-weight: 800;
      color: $white;
      text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
    }

    .supporters__img {
      height: 70px;
      width: auto;
    }

    .outro__results {

      h2 {
        font-size: 2rem;
        color: $color-white;
        font-family: $font-secondary;
        font-weight: 400;
      }

      .outro__result-container {
        padding: 0 5rem;

        .outro__item {
          width: calc(25% - .6rem);
          margin-right: .8rem;

          &:nth-of-type(4) {
            margin-right: 0;
          }

          .outro__icon {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            border: 3px solid $gray-200;
            background: $color-white;
            margin: auto;

            img {
              width: 50%;
              height: 50%;
            }
          }

          .outro__value {

            h3 {
              font-size: 1.6rem;
              font-weight: 800;
              color: $color-white;
            }

            p {
              font-size: 1.4rem;
              font-weight: 600;
              color: $color-white;
            }

          }
        }
      }
    }
  }

  &.modal__intro {
    background-size: cover;

    .modal__body {
      height: 600px;
    }

    &.blue {
      background-image: url("/img/intro-modal_bg1.png");

    }

    &.red {
      background-image: url("/img/intro-modal_bg2.png");
    }

    h2, p {
      color: $color-white;
    }

    h2 {
      font-family: $font-secondary;
      font-size: 2.6rem !important;
      line-height: 2.6rem !important;
      font-weight: 400;
      text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
    }

    .modal__right {
      position: relative;
      overflow: hidden;

      .intro__bubble {
        position: absolute;
        left: 0;
        top: 100px;
        width: 100%;
        z-index: 0;
      }
    }

    .btn--m {
      padding: .8rem 1.6rem;
    }
  }

  .modal__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 30px;
    height: 30px;
    font-size: 1.2rem;
    background: none;
    border-radius: 50%;
    padding: 0;
    z-index: 100;
    line-height: 1.8rem;
    cursor: pointer;
    border: none;

    .mdi {
      color: $white;
      line-height: 2rem;
    }

    &.dark {
      background: rgba(240, 240, 240, .8);

      .mdi {
        color: $dark;
      }
    }
  }

  h4 {
    font-size: 1.8rem;
    font-weight: 600;
  }

  h5 {
    font-size: 1rem;
    font-weight: 600;
  }

  p {
    font-size: 1.1rem;
    line-height: 1.8rem;
    color: $dark;
    font-weight: 500;
  }

  &.modal--m {
    width: 550px;
  }

  &.modal--l {
    width: 950px;
  }

  &.modal--xl {
    width: calc(100vw - 100px);
    max-width: 640px;
  }

  &.success {
    border-left: 8px solid $green-400;

    .btn--primary {
      background: $green-400;
      color: $white;

      &:hover {
        background: darken($green-400, 3%);
      }
    }
  }

  &.danger {
    border-left: 8px solid $red;

    &.btn--primary {
      background: $red-400;
      color: $white;

      &:hover {
        background: darken($red-400, 3%);
      }
    }
  }

  &.warning {
    border-left: 8px solid $orange;

    .btn--primary {
      background: $orange;
      color: $white;

      &:hover {
        background: darken($orange, 3%);
      }
    }
  }

  .modal__header {

  }

  .modal__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .7);
    display: flex;

    & > div {
      margin: auto;
    }

    .lds-ellipsis {
      display: inline-block;
      position: relative;
      width: 64px;
      height: 64px;
    }

    .lds-ellipsis div {
      position: absolute;
      top: 27px;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background: #3d3d3d;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }

    .lds-ellipsis div:nth-child(1) {
      left: 6px;
      animation: lds-ellipsis1 0.6s infinite;
    }

    .lds-ellipsis div:nth-child(2) {
      left: 6px;
      animation: lds-ellipsis2 0.6s infinite;
    }

    .lds-ellipsis div:nth-child(3) {
      left: 26px;
      animation: lds-ellipsis2 0.6s infinite;
    }

    .lds-ellipsis div:nth-child(4) {
      left: 45px;
      animation: lds-ellipsis3 0.6s infinite;
    }

    @keyframes lds-ellipsis1 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes lds-ellipsis3 {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
    @keyframes lds-ellipsis2 {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(19px, 0);
      }
    }
  }

  .modal__body {
    position: relative;

    h2 {
      font-size: 1.8rem;
    }

    p {
      font-size: 1.2rem;
      line-height: 1.5rem;
    }

    &.modal__outro {
      position: relative;
      overflow: hidden;
      height: 600px;
      background-size: cover;

      &.section__outro {
        background: url("/img/section-outro_bg.png") top left;
        background-size: cover;

        &.bad {
          background: url("/img/section-outro_bg.png") top left;
        }

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 40%;
          height: 60%;
          width: 100%;
          background-image: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
          z-index: 0;
        }

        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          height: 40%;
          width: 100%;
          background: $white;
          z-index: 0;
        }

        .modal__inner {
          z-index: 1;
          padding-top: 70px;
          padding-bottom: 70px;
          position: relative;
        }

        h2 {
          font-family: $font-secondary;
          font-size: 3.2rem;
          line-height: 3.4rem;
          font-weight: 400;
        }

      }

      &.quiz {
        background-image: url("/img/intro-modal_bg1.png");
      }

      .modal__outro-icon {
        width: 120px;
        height: auto;
      }

      .outro-marvin__left {
        position: absolute;
        top: 100px;
        left: -150px;
        width: 300px;
      }

      .outro-marvin__right {
        position: absolute;
        top: 100px;
        right: -50px;
        width: 300px;
      }

      .outro__points {

        .points__left {
          font-size: 1.2rem;
        }

        .points__right {
          font-size: 1rem;

          img {
            width: 19px;
            height: 19px;
          }
        }

        .progress-container {
          width: 150px;
        }

      }

      .modal__outro--bottom_btn {
        position: absolute;
        bottom: 0;
        left: calc(50% - 125px);
        border-bottom: 40px solid #fff;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        height: 0;
        width: 250px;

        .btn {
          height: 40px;
          text-decoration: underline;
          font-size: .9rem;
          font-weight: 600;
          padding: .6rem;
        }
      }

      .outro__buttons {

      }
    }

    &.modal__scenario {

      &.select {
        background: $gray-100;
      }

      .modal__inner {
        min-height: 450px;
      }
    }


    &.modal__module-finish {
      background-size: cover;
      background-position: center center;
      background-image: url("/img/modules/bg/bg-empathy.svg");

      h2, p {
        color: $white;
      }

      h2, .subtitle {
        font-family: $font-secondary;
      }

      h2 {
        font-size: 4.4rem;
      }

      .subtitle {
        font-size: 2.4rem;
      }

      p {
        font-size: 1.4rem;
      }

      img {
        width: 250px;
        height: auto;
      }

      .btn--text {
        color: white;
      }

      &.empathy {
        background-image: url("/img/modules/bg/bg-empathy.svg");
      }

      &.idea {
        background-image: url("/img/modules/bg/bg-idea.svg");
      }

      &.socialneeds {
        background-image: url("/img/modules/bg/bg-socialneeds.svg");
      }

      &.sustainable {
        background-image: url("/img/modules/bg/bg-sustainable.svg");
      }

      &.ideageneration {
        background-image: url("/img/modules/bg/bg-ideageneration.svg");
      }

      &.visionary {
        background-image: url("/img/modules/bg/bg-visionary.svg");
      }

      .btn {
        position: relative;

        img {
          position: absolute;
          right: 25px;
          top: 13px;
          width: 20px;
          height: auto;
        }
      }
    }

    &.modal__cs {

      .modal__inner {
        height: 550px;
      }
    }

    .modal__top {
      height: 250px;
      width: 100%;
      overflow: hidden;
      position: relative;
    }

    .modal__bottom {

    }

    img {
      //max-width: 100%;
    }

    .modal__inner {
      padding: 2rem;
    }

    .modal__item {

      p {
        margin: 1rem 0;

        &:first-of-type {
          margin-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .modal__marvin {
      z-index: 1;
      position: absolute;
      top: 50px; // todo - preverit, ci to nerozbija ine modaly (bolo 100px)
      right: 0;
      height: 600px;
    }

    .bg--featured, .bg--warning {

      h2 {
        color: $white !important;
      }

      p {
        color: $white !important;
        font-weight: 600;
      }

      .btn--white {
        border: none;
      }

      .btn--text {
        color: $white;
      }
    }
  }

  .modal__footer {
    padding: 2rem;
    background: #fafafa;
  }

  .modal__slider {
    padding: .5rem 1.5rem;

    .mdi {
      font-size: 2rem;
      color: $light;
    }

    .slider__dots {

      .slider__dot {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 1px solid $light;
        margin-right: .8rem;

        &:last-of-type {
          margin-right: 0;
        }

        &.active {
          background: $color-secondary;
          border: 1px solid $color-secondary;
          box-shadow: 1px 1px 3px rgba(90, 215, 238, .8);
        }
      }
    }
  }

  .slider {
    padding: .5rem 1.5rem;

    .mdi {
      font-size: 2rem;
      color: $light;
    }

    .slider__dots {

      .slider__dot {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 1px solid $light;
        margin-right: .8rem;

        &:last-of-type {
          margin-right: 0;
        }

        &.active {
          background: $color-secondary;
          border: 1px solid $color-secondary;
          box-shadow: 1px 1px 3px rgba(90, 215, 238, .8);
        }
      }
    }
  }

  .modal__confirmation {
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background: rgba(255, 255, 255, 0.95);
    z-index: 101;

    h2 {
      font-size: 2.4rem;
      color: $dark;
    }

    p {
      color: $bright;
    }
  }

  .modal-cs-person {
    position: relative;
    width: 200px;

    img {
      width: auto;
    }
  }

  .text-bubble {
    position: absolute;
    top: 0;
    width: 300px;
    height: auto;
    background: $color-secondary;
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
    z-index: 1;

    p {
      margin: 0;
      font-weight: 600;
      color: $color-white;
    }

    &.left {
      right: calc(100% - 50px);
      left: auto;

      &:after {
        content: '';
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: none;
        border-left: 10px solid $color-secondary;
        right: -10px;
        top: 30px;
      }
    }

    &.right {
      left: calc(100% - 50px);

      &:after {
        content: '';
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: none;
        border-right: 10px solid $color-secondary;
        left: -10px;
        top: 30px;
      }
    }

    &:after {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: none;
      border-right: 10px solid white;
      top: 70px;
    }

    .bubble__inner {

      p {
        font-size: 1.2rem;
        line-height: 1.6rem;
      }

    }
  }
}

@keyframes modal-backdrop-animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes modal-content-animation {
  0% {
    opacity: 0;
    top: 0;
  }

  100% {
    opacity: 1;
    top: 50px;
  }
}

@keyframes modal-content-animation-mobile {
  0% {
    opacity: 0;
    top: 0;
  }

  100% {
    opacity: 1;
    top: 10px;
  }
}

@media (max-width: 1024px) {

  .modal {
    width: 95% !important;
    border-radius: 10px !important;
    border: none !important;
    animation: modal-content-animation-mobile .5s;
    animation-fill-mode: forwards;
    animation-delay: .15s;

    h4 {
      font-size: 1.4rem;
    }

    &.modal__office {

      .modal__body {

        .img__right {
          position: absolute;
          right: 0;
          top: 50px;
          width: 50%;
        }
      }

      h2 {
        font-size: 1.8rem !important;
        line-height: 2.1rem !important;
      }

      p {
        font-size: 1rem !important;
      }
    }

    &.modal__intro {

      .modal__body {
        height: auto;

        img {
          height: 300px !important;
        }

        .modal__right {
          overflow: visible;
        }
      }

      .btn--m {
        padding: .5rem 1rem;

      }
    }

    .modal__full {
      padding: 1rem !important;
    }

    .modal__body {
      overflow: auto;

      .modal__inner {
        padding: 1rem;
      }

      h2 {
        font-size: 1.4rem !important;
        line-height: 1.6rem !important;
      }

      p {
        font-size: .9rem !important;
        line-height: 1.2rem !important;
      }

      &.modal__scenario {

        .modal__inner {
          height: 80vh;
        }
      }

      &.modal__outro {
        height: 100%;

        .modal__outro-icon {
          width: 60px;
        }

        .outro-marvin__left {
          position: absolute;
          top: 100px;
          left: -150px;
          width: 300px;
        }

        .outro-marvin__right {
          position: absolute;
          top: 100px;
          right: -50px;
          width: 300px;
        }

        .outro__points {

          .points__left {
            font-size: .9rem !important;
          }

          .points__right {
            font-size: .9rem !important;

            img {
              vertical-align: middle;
            }
          }

          .progress-container {
            width: 100px !important;
            margin-left: 10px;
            margin-right: 10px;
          }

        }

        .outro__results {

          h2 {
            font-size: 2rem !important;
          }

          .outro__result-container {
            padding: 0 !important;

            .outro__icon {
              width: 60px !important;
              height: 60px !important;
            }

            .outro__value {

              h3 {
                font-size: 1.4rem !important;
              }

              p {
                font-size: .9rem !important;
              }

            }
          }
        }
      }

      &.modal__module-finish {

        h2 {
          font-size: 2rem !important;
        }

        .subtitle {
          font-size: 1.6rem;
        }

        p {
          font-size: 1rem;
        }

        img {
          width: 200px;
        }

        .btn {

          img {
            top: 7px;
          }
        }
      }
    }

    .modal__confirmation {
      position: fixed;
      padding: 1rem;
    }

    .modal__marvin {
      height: auto !important;
    }

    .modal__close {
      position: fixed;
      top: 1.4rem;
      right: 1.4rem;
    }
  }

}
@import "variables";
@import "reset";
@import "header";
@import "footer";
@import "buttons";
@import "general";
@import "modifiers";
@import "card";
@import "form";
@import "login";
@import "question-types/common";
@import "question-types/multiple-choices";
@import "hamburger";
@import "alert";
@import "label";
@import "disclaimer";
@import "scenarios";
@import "modals";
@import "settings";
@import "sidebar";
@import "marketplace";
@import "slider";
@import "office";
@import "marvin";
@import "theory";
@import "intro";
@import "modules";
@import "quiz-slider";
@import "drop";
@import "editor";
@import "table";
@import "progressbar";
@import "bmc";
@import "open-tasks";
@import "flags16";
@import "flags32";
@import "chat";
@import "flashing";
@import "spinner";
@import "infopanel";

.button-clear {
  -webkit-appearance: none;
  -webkit-writing-mode: inherit;
  text-rendering: auto;
  color: inherit;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: pointer;
  background: none;
  box-sizing: border-box;
  margin: inherit;
  font: inherit;
  padding: inherit;
  border: none;
}

.button-clear:hover {
  text-decoration: none;
  background: transparent;
}

.button-clear:active {
  box-shadow: none;
  outline: none;
}

.button-clear:focus {
  box-shadow: none;
  outline: none;
}

.loader {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(20, 20, 20, 0.9);
  display: flex;

  & > div {
    margin: auto;
    color: white;
  }

  .loader__submit {
    height: 300px;
  }
}
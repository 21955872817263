@-webkit-keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinner{
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  display: inline-block;
  &:after {
    content: " ";
    display: block;
    width: 8px;
    height: 8px;
    margin: 4px;
    border-radius: 50%;
    border: 6px solid $color-primary;
    border-color: $color-primary transparent $color-primary transparent;
    animation: spinner 1.2s linear infinite;
  }
}
.disclaimer {
  position: fixed;
  left: 10px;
  bottom: 10px;
  height: 80px;
  width: calc(100% - 20px);
  background-color: rgba(255, 255, 255, .9);
  border-radius: 10px;
  padding: .5rem;

  .disclaimer__wrapper {
    width: 100%;

    .disclaimer__logo {

      img {
        width: 150px;
      }

    }

    .disclaimer__copyright {
      margin-right: 3rem;
    }

    .disclaimer__text, .disclaimer__copyright {

      p {
        color: $color-dark;
        font-size: 1rem;
        line-height: 1.2rem;
      }
    }
  }
}

@media (max-width: 1200px) {

  .disclaimer__wrapper {
    padding: 0 1rem;
  }
}

@media (max-width: 1024px) {

  .disclaimer {
    position: relative;
    left: 0;
    bottom: 0;
    height: auto;
    width: 100%;
    padding: 1rem 0;
    margin: 0;
    flex-basis: 1009%;

    &:before {
      height: 0;
      width: 0;
    }

    .disclaimer__wrapper {
      flex-direction: column;
    }

    .disclaimer__text {

      p {
        margin-top: 1rem;
        font-size: .8rem !important;
        line-height: 1.2rem !important;
      }
    }
  }
}

.modules-item {
  width: calc(50% - .6rem);
  margin-right: 1.2rem;
  margin-bottom: 1.2rem;
  text-align: left;
  position: relative;
  overflow: hidden;
  //border: 1px solid rgba(0, 0, 0, .1) !important;
  z-index: 1;

  &:nth-of-type(2n) {
    margin-right: 0;
  }

  h2 {
    font-size: 1.6rem;
    font-weight: 700;
    color: $color-black;
  }

  p {
    color: $color-gray;
    line-height: 1.6rem;
  }

  .module__disabled {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(100, 100, 100, .9);
    z-index: 1;

    img {
      width: 40px;
    }
  }

  .module__section {
    border-radius: 5px;
    padding: .4rem;
    flex-grow: 1;
    flex-basis: 0;
    position: relative;
    transition: all .2s;
    margin-right: .5rem;
    cursor: pointer;
    font-size: .8rem;
    font-weight: 600;
    max-width: 25%;

    &:last-of-type {
      margin-right: 0;
    }

    &:hover {
      background: #f2f2f2;
    }

    button {
      font-size: .8rem;
    }

    .mdi {
      line-height: 1;
    }

    .module__label {
      position: absolute;
      top: -10px;
      right: -5px;
      font-size: .6rem;
      font-weight: 700;
      border-radius: 3px;
      padding: .2rem .3rem;
    }

    &.completed {
      border: 1px solid $color-secondary;
      color: $color-secondary;

      .module__label {
        background: $gray-600;
        color: $white;
      }
    }

    &.evaluated {
      border: 1px solid $color-secondary;
      color: $color-secondary;

      .module__label {
        background: $gray-600;
        color: $white;
      }
    }

    &.locked {
      border: 1px solid $gray-300;
      opacity: .4;
      cursor: not-allowed;

      &:hover {
        background: transparent;
      }

      .module__label {
        background: $gray-400;
        color: $white;
      }
    }

    &.open, &.in_progress {
      background: $color-secondary;
      color: $white;

      .module__label {
        background: $color-warning;
        color: $white;
      }
    }

    &.incompleted {
      background: $color-secondary;
      color: $white;

      .module__label {
        background: $pink-warning;
        color: $white;
      }
    }
  }
}

@media (max-width: 1024px) {

  .modules-item {
    width: 100%;
    margin-right: 0;

    h2 {
      font-size: 1.2rem;
    }

    p {
      font-size: .8rem;
      line-height: 1.2rem;
    }

    .module__section {
      width: calc(50% - .5rem);
      margin-right: 1rem;
      margin-bottom: 1rem;
      flex-basis: auto;
      flex-grow: unset;
      max-width: unset;

      &:nth-of-type(2n) {
        margin-right: 0;
      }

      .mdi {
        line-height: 1;
      }

      .module__label {
        position: absolute;
        top: -10px;
        right: -5px;
        font-size: .6rem;
        font-weight: 700;
        border-radius: 3px;
        padding: .2rem .3rem;
      }

      &.completed {
        border: 1px solid $color-secondary;
        color: $color-secondary;

        .module__label {
          background: $gray-600;
          color: $white;
        }
      }

      &.locked {
        border: 1px solid $gray-300;
        opacity: .4;
        cursor: not-allowed;

        &:hover {
          background: transparent;
        }

        .module__label {
          background: $gray-400;
          color: $white;
        }
      }

      &.open {
        background: $color-secondary;
        color: $white;

        .module__label {
          background: $color-warning;
          color: $white;
        }
      }

      &.in_progress {
        background: $color-secondary;
        color: $white;

        .module__label {
          background: $color-warning;
          color: $white;
        }
      }

      &.incompleted {
        background: $color-secondary;
        color: $white;

        .module__label {
          background: $pink-warning;
          color: $white;
        }
      }
    }
  }
}
.table-container {
  overflow: auto;
}


.table {
  border-radius: 8px;
  border: 1px solid $gray-300;
  border-spacing: 0;
  //overflow: hidden;
  font-size: .9rem;

  &.table--results {

    thead {

      tr {

        th:nth-of-type(3) {
          background: #F9D34A;
        }
      }
    }

    tbody {

      tr {

        td:nth-of-type(3) {
          background: #F9E9AE;
        }

        &.table--featured {

          td:nth-of-type(3) {
            background: darken($color-secondary, 20%);
          }
        }
      }
    }

  }

  .table--featured {
    background: $color-secondary !important;
    color: $white;
    font-weight: 800;
  }

  .table__icon {
    height: 20px;
    width: auto;
  }

  tr {

    td {
      padding: 1rem;
      background: transparent;
    }

    &:nth-of-type(2n) {
      background: $gray-100;
    }

    th {
      padding: .5rem 1rem;
    }

    &:last-of-type {

      td:first-of-type {
        border-radius: 0 0 0 8px;
      }

      td:last-of-type {
        border-radius: 0 0 8px 0;
      }
    }
  }

  thead {

    tr {
      background: $gray-200;

      th {
        border-bottom: 2px solid $gray-300;

        &:first-of-type {
          border-radius: 8px 0 0 0;
        }

        &:last-of-type {
          border-radius: 0 8px 0 0;
        }
      }
    }

  }

  .tbody {

  }
}


@media (max-width: 1024px) {

  .table {

    .table--featured {
      transform: scale(1) !important;

      td {
        border-radius: 0 !important;
      }
    }
  }


}
.intro-wrapper {
  background-size: cover;
  position: fixed;
  width: 100%;
  height: calc(100% - 65px);
  margin-top: 65px;
  align-items: center;

  &.video {
    background: rgba(159, 214, 227, 1);
  }

  .supporters--bg {
    height: 100vh;
    width: 100vw;
  }

  .theory-container {
    margin-top: 0 !important;
  }

  .intro__video {
    overflow: hidden;
    border-radius: 10px;
    padding: 1rem;
    background: rgba(0, 0, 0, .1);

    video {
      width: 800px;
      height: auto;

      &:focus {
        outline: none;
      }
    }
  }
}

@media (max-width: 1024px) {

  .intro-wrapper {
    margin-top: 45px;
    height: calc(100% - 45px);

    .theory-container {
      height: 100% !important;
      top: 0 !important;
      margin-top: 0 !important;
    }
  }

  .intro__video {
    width: calc(100% - 10%);
    margin-left: 0;

    video {
      width: 100% !important;
    }
  }
}
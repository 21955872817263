.marvin-container {
  position: absolute;
  bottom: -80px;
  z-index: 1;

  .marvin__img {
    height: calc(80vh - 80px);
    width: auto;

    &.double {
      left: calc(50% - 9rem);
      position: fixed;
    }
  }
}

.text-bubble-theory {
  width: 300px;
  z-index: 1;
  max-height: 450px;

  &.left {
    right: 100%;
    top: 0;
  }
}

.supporters-bubble {
  bottom: 500px;
  left: calc(50% - 200px);
  width: 400px;
  position: absolute;
  background: $white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, .2);
  z-index: 1;
  opacity: 0;
  transform: scale(0);
  animation-name: bubble-in;
  animation-duration: .02s;
  animation-fill-mode: forwards;

  p {
    font-size: 1.1rem;
    line-height: 1.6rem;
    font-weight: 500;
  }

  &:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: none;
    top: 100%;
    left: calc(50% - 10px);
  }
}

.text-bubble {
  position: absolute;
  top: 0;
  width: 450px;
  height: auto;
  background: #fff;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  z-index: 1;

  &.m {
    width: 350px;
  }

  &:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    top: 30px;
  }

  &.right {
    left: 100%;

    &:after {
      left: -10px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: none;
      border-right: 10px solid white;
    }
  }

  &.left {
    left: auto;
    right: 100%;

    &:after {
      right: -10px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid white;
      border-right: none;
    }
  }

  &.top {
    right: 50%;

    &:after {
      right: -10px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid white;
      border-right: none;
    }
  }

  .bubble__inner {

    p {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }

  }
}

@media (max-width: 1024px) {

  .supporters-bubble {
    left: 10px;
    width: calc(100% - 20px);
    top: 40px !important;
    bottom: unset;
    padding: .5rem;

    p {
      font-size: .8rem;
      line-height: 1rem;
    }

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-top: 10px solid white;
      border-bottom: none;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      left: calc(50% - 5px);
      top: 100%;
    }
  }

}
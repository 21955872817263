.slider {

  button {
    margin: 0;
  }

  .mdi {
    font-size: 2rem;
    color: $light;
  }

  .slider__dots {

    .slider__dot {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 1px solid $light;
      margin-right: .8rem;

      &:last-of-type {
        margin-right: 0;
      }

      &.active {
        background: $color-secondary;
        border: 1px solid $color-secondary;
        box-shadow: 1px 1px 3px rgba(90, 215, 238, .8);
      }
    }
  }
}
.flashing, .flashing-channel {

  &:after {
    content: '';
    background: $color-danger;
    border-radius: 30px;
    height: 15px;
    width: 15px;
    position: absolute;
    left: 10px;
    top: 10px;
    animation: pulse-animation 2s infinite;
  }

}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255,48,75, 0.5);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(255,48,75, 0);
  }
}
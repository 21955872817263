.input-container {
  position: relative;
  margin-bottom: 1rem;

  &:last-of-type, &:only-of-type {
    margin-bottom: 0;
  }

  .error__msg {
    color: $red-500;
    margin-left: 1.2rem;
  }

  label {
    position: relative;
  }

  .input__item {
    height: 50px;
    border-radius: 50px;
    font-size: 1.1rem;
    padding: 0 1rem;
    box-sizing: border-box;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, .1);
    border: 1px solid $dash-input-border;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $color-gray;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $color-gray;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: $color-gray;
    }

    &.m {
      height: 45px;
      font-size: .9rem;
    }

    &:focus {
      outline: none;
      box-shadow: none;
      border: 1px solid $color-secondary;
    }

    &.icon__after {
      padding-right: 50px;
    }
  }

  .mdi {
    position: absolute;
    right: 15px;
    top: 12px;
    font-size: 1.4rem;
    color: $color-gray;
  }
}

.img--avatar {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, .1);
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

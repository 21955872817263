.card-container {

  .card {
    background: $color-white;
    border-radius: 10px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, .1);
    border: none;
    padding: 1.5rem;
  }

  &.card--disabled__task {

    .card {
      background-color: rgba(241, 167, 91, .5);
      //box-shadow: 3px 3px 10px rgba(0, 0, 0, .1);

      h2, p {
        color: #fff;
      }
    }
  }
}

@media (max-width: 640px) {

  .card-container {

    .card {
      box-shadow: 1px 1px 4px rgba(0, 0, 0, .2);
      padding: 1rem;
    }
  }
}

@media (min-width: 1000px) and (max-width: 1200px) {

  .card-container {

    .card {
      padding: 1.2rem;
    }
  }
}

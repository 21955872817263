.question-container {
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  padding: 125px 160px 100px 100px;
  position: relative;

  &.bg-default {
    background: url("/img/bg-quiz.png") no-repeat;
    background-size: cover;
  }

  &.bg-green {
    background: url("/img/bg-quiz_fun.png") no-repeat;
    background-size: cover;
  }

  .question__close {
    position: fixed;
    top: 5.5rem;
    right: 5.2rem;
    width: 50px;
    height: 50px;
    font-size: 2rem;
    background: rgba(255, 255, 255, .1);
    border-radius: 50%;
    padding: 0;
    z-index: 100;
    line-height: 3.2rem;
    cursor: pointer;
    border: none;

    .mdi {
      color: $white;
      line-height: 3.2rem;
    }

    &.dark {
      background: rgba(0, 0, 0, .1);

      .mdi {
        color: $white;
      }
    }
  }

  .btn-container {
    position: fixed;
    bottom: 40px;
    right: 160px;

    &.quiz {
      width: auto;
    }

    .invalid--answer {
      position: absolute;
      right: 0;
      bottom: calc(100% + 15px);
      color: $color-white;
      background: $color-black;
      border-radius: 50px;
      padding: 1rem 1.5rem;

      &:after {
        content: '';
        position: absolute;
        right: 30px;
        top: 100%;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid $color-black;
      }

      .mdi {
        font-size: 1.2rem;
        vertical-align: middle;
      }
    }
  }
}

.question-header-container {
  color: rgba(51, 51, 51, 1);

  .question__quiz {
    font-size: 4rem;
    font-family: $font-secondary;
  }

  .question__name {
    font-size: 1.8rem;
  }

  .question__heading {
    font-size: 2rem;
    font-weight: 600;
  }

  .question__definition {
    font-size: 1rem;
    line-height: 1.6rem;
    font-weight: 600;
    padding: .8rem .8rem .8rem 1.6rem;
    border-radius: 0 10px 10px 0;
    box-shadow: inset 5px 0 0 $dark;
    background: rgba(255, 255, 255, .4);
    font-style: italic;
  }

  .question__subheading {
    font-size: 1.1rem;
    font-weight: 400;
  }

  //.header__right {
  //
  //  .header__info {
  //    height: 30px;
  //    width: 30px;
  //    border-radius: 50%;
  //    background: $color-info;
  //    color: $color-white;
  //    position: relative;
  //    cursor: pointer;
  //
  //    .info__icon {
  //
  //    }
  //
  //    .info__dropdown {
  //      background: $color-info;
  //      position: absolute;
  //      left: 0;
  //      top: 0;
  //      border-radius: 20px;
  //      width: 200px;
  //      height: auto;
  //      padding: .7rem .7rem .7rem 2.2rem;
  //      display: none;
  //
  //      &.active {
  //        display: block;
  //      }
  //
  //      .info__close {
  //        color: $color-white;
  //        position: absolute;
  //        top: .7rem;
  //        left: 1rem;
  //      }
  //
  //      p {
  //        font-size: .9rem;
  //        line-height: 1.4rem;
  //      }
  //    }
  //  }
  //
  //}
}

.question-item-container {

  .question__item {
    padding: 5px 1.5rem 5px 5px;
    background: $color-white;
    border-radius: 50px;
    font-size: 1.2rem;
    font-weight: 800;
    position: relative;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, .1);
    cursor: pointer;
    min-width: 300px;

    .question__item-options {
      height: 45px;
      width: 45px;
      border-radius: 50%;
      background: $white;
      border: 1px solid $color-bright;
      align-items: center;
    }

    .question__item-content {
      font-size: 1rem;
      font-weight: 500;

      img {
        height: 30px;
        width: auto;
        position: absolute;
        right: 20px;
        top: 0;
      }
    }

    &.correct {
      background: $color-white;

      .question__item-options {
        background: $color-correct;
        border: 1px solid $color-correct;

        span {
          color: $white;
        }
      }
    }

    &.incorrect {
      background: $color-white;

      .question__item-options {
        background: $color-incorrect;
        border: 1px solid $color-incorrect;

        span {
          color: $white;
        }
      }
    }

    &.selected {
      background: $color-white;

      .question__item-options {
        background: $color-active;
        border: 1px solid $color-active;

        span {
          color: $white;
        }
      }
    }
  }
}

.fill-item-container {
  border-radius: 0 20px 20px 0;

  .fill__item {
    background: $color-white;
    padding: 1rem 1.5rem 1rem 1.5rem;
    font-size: 1.2rem;
    font-weight: 800;
    position: relative;
    cursor: pointer;
    border-radius: 27px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, .1);

    &.fill__select {
      background: $color-white;
      color: $color-bright;
      min-width: 280px;
      padding-right: 4rem;

      .mdi {
        color: $color-bright;
        position: absolute;
        right: 1rem;
        top: .7rem;
        font-size: 2rem;
      }
    }

    &.correct {
      background: $color-white;

      .question__item-options {
        background: $color-correct;
        border: 1px solid $color-correct;

        span {
          color: $white;
        }
      }
    }

    &.incorrect {
      background: $color-white;

      .question__item-options {
        background: $color-incorrect;
        border: 1px solid $color-incorrect;

        span {
          color: $white;
        }
      }
    }

    &.selected {
      background: $color-active;
      color: $white;

      .mdi {
        color: $color-white;
      }
    }

    &.active {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background: $color-white;
      color: $color-gray;

      .dropdown {
        display: block;
      }
    }

    &.option {
      background: $color-gray;
      color: $color-white;
    }

    .dropdown {
      display: none;
      position: absolute;
      background: $color-white;
      width: 100%;
      left: 0;
      top: 100%;
      z-index: 2;
      border-radius: 0 0 27px 27px;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, .1);

      .dropdown__item {
        padding: .8rem 1.5rem;
        transition: all .2s;

        &:last-of-type {
          border-radius: 0 0 27px 27px;
        }

        &:hover {
          background: $color-secondary;
          color: $color-white;
        }

        &.active {
          background: $color-active;
          color: $color-white;
        }

      }
    }
  }
}

.order-item-container {

  .order__item {
    background: $color-white;
    border-radius: 27px;
    color: $color-gray;
    font-size: 1.2rem;
    font-weight: 800;
    position: relative;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, .1);

  }

  .order-item__left {

    .order__item {
      cursor: grab;
      color: $color-gray;
      width: 54px;
      height: 54px;

      &.active {
        background: $color-active;
        color: $color-white;
      }
    }
  }

  .order-item__right {

    .order__item {
      cursor: grab;
      color: $color-primary;
      padding: 1rem 1.5rem;
      min-width: 400px;

      &.active {
        background: $color-secondary;
        color: $color-white;
      }
    }

  }
}

.connect-item-container {

  .connect__item {
    background: $white;
    border: 1px solid $white;
    border-radius: 25px;
    padding: .8rem 1.3rem;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    position: relative;

    &.shrinked {
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }

    .connect__item-more {
      position: absolute;
      top: 16px;
      right: 15px;
      height: 15px;
      width: auto;
    }

    &.active {
      background: rgba(255, 255, 255, .5);
      border: 1px solid rgba(255, 255, 255, .1);

      //span {
      //  opacity: 0;
      //}
    }
  }

  .connect-item__top {
    min-height: 60px;
    max-height: 100px;
    background: rgba(255, 255, 255, 0.3);
    padding: 1rem 1rem 0 1rem;
    border-radius: 10px;
    overflow: auto;
  }

  .connect-item__bottom {
    padding: 2rem 0;
    background: rgba(255, 255, 255, .3);
    position: relative;
    height: 35vh;
    overflow-y: auto;
    width: calc(100vw - 74px);
    margin-left: -100px;
    margin-right: -100px;

    .connect-item__inner {
      margin-left: 100px;
      margin-right: 160px;
    }

    .connect-item__row {

      .connect__item {

        &.filled {
          background: $color-secondary;
          border: 1px solid $color-secondary !important;
          color: $white !important;
        }
      }

      .connect-item__column {

        &.left {
          width: 250px;
          flex-shrink: 0;

          .connect__item {
            border: 1px solid $gray-300;

            &.empty {
              color: $gray-400;
            }
          }
        }

        &.right {
          width: calc(100% - 250px - 2rem);

          .connect__item {
            padding-right: 100px;
          }
        }
      }
    }

  }

}

.word-item-container {

  .word__item {
    background: $color-white;
    border-radius: 25px;
    font-size: 1.2rem;
    font-weight: 800;
    position: relative;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, .1);
  }

  .letter-item__area {
    width: 60vw;
    height: 250px;
    background: rgba(0, 0, 0, .2);
    border-radius: 25px;

    .letter__item {
      padding: 0;
      color: $color-gray;
      width: 45px;
      height: 45px;
      font-size: 1rem;
      cursor: grab;
      background: $color-white;
      border-radius: 50px;
      font-weight: 800;
      position: relative;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, .1);

    }
  }

  .word-item__fill {

    .word__item {
      padding: .5rem;
      font-size: 1.2rem;
    }

    .letter__container {
      margin-right: 2rem;
      line-height: 0;

      &:last-of-type {
        margin-right: 0;
      }

      .letter__item {
        position: relative;
        margin-right: .5rem;
        width: 45px;
        height: 45px;
        border: 1px solid $color-bright;
        border-radius: 50px;
        box-sizing: border-box;
        cursor: grab;
        padding-top: 8px;

        &.correct {
          background: $color-correct;
          color: $color-white;
        }

        &.incorrect {
          background: $color-incorrect;
          color: $color-white;
        }

        &:last-child {
          margin-right: 0;
          //  background: lighten($color-info, 45%);
          //  border: 1px dashed $color-info;
        }

        .letter__wrapper {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: flex;

          .letter__char {
            display: inline-block;
            margin: auto;
          }
        }
      }
    }
  }
}

.quiz-marvin {
  position: fixed;
  left: 100px;
  bottom: -350px;

  .marvin__img {
    width: 240px;
  }

  .text-bubble {
    position: absolute;
    top: 0;
    bottom: auto;
    left: 200px;
    width: 200px;
    padding: 1.5rem 1.5rem 3rem 1.5rem;

    p {
      font-weight: 700;
    }

    .btn {
      position: absolute;
      left: 50px;
      bottom: -10px;
      width: 100px;
      font-size: 1.4rem;
      text-align: left;

      img {
        height: 25px;
        position: absolute;
        top: 10px;
        right: 20px;
      }
    }

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: none;
      border-right: 10px solid white;
      left: -10px;
      top: calc(50% - 10px);
      position: absolute;
    }
  }
}


@media (max-width: 1024px) {

  .btn-container {

    .invalid--answer {
      left: 0;
      right: unset !important;
      padding: .5rem 1rem !important;
      font-size: .8rem;
    }
  }

  .question-container {
    padding: 60px 60px 100px 20px;

    .btn-container {
      left: 20px;

      &.quiz {
        width: 100%;
      }
    }

  }

  .question-header-container {

    .question__quiz {
      font-size: 2rem;
      width: 100%;
    }

    .question__name {
      font-size: 1rem;
    }

    .question__heading {
      font-size: 1.2rem;
    }
  }

  .question-item-container {

    .question__item {
      min-width: unset;
      max-width: 100%;
    }
  }

  .question__close {
    top: 5rem !important;
    right: 3rem !important;
    width: 30px !important;
    height: 30px !important;
    font-size: 1.4rem !important;

    .mdi {
      line-height: 1.9rem !important;
    }
  }

  .question__item-options {
    height: 35px !important;
    width: 35px !important;
    flex-shrink: 0 !important;
  }

  .question__item-content {
    font-size: .8rem !important;
  }

  .fill__item {
    padding: .7rem 2.5rem .7rem 1.2rem !important;
    font-size: 1rem !important;
    min-width: unset !important;
    max-width: 100%;

    &.fill__select {

      .mdi {
        top: .5rem !important;
        font-size: 1.6rem !important;
      }
    }
  }

  .connect__item {
    border-radius: 15px !important;
    padding: .3rem .4rem !important;
    font-size: .8rem !important;

    .connect__item-more {
      top: 10px !important;
      height: 10px !important;
    }
  }

  .connect-item__top {
    max-height: unset !important;
  }

  .connect-item__bottom {
    padding: 1rem !important;
    height: auto !important;
    width: calc(100vw - 36px) !important;
    margin-left: -20px !important;

    .connect-item__inner {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .connect-item__row {

      .connect-item__column {

        &.left {
          width: 150px !important;
        }

        &.right {

          .connect__item {
            padding-right: 40px !important;
            width: 300px !important;
          }
        }
      }
    }

  }
}
.login-container {
  z-index: 1;
  position: relative;

  .error {
    padding: 1rem 2rem;
    font-size: 1rem;
    border-radius: 50px;
    font-weight: 800;
    background: $color-danger;
    color: $color-white;
    margin-bottom: 1rem;
  }

  .login__subheading {
    font-size: 1rem;
  }

  .login__lang {
    border-radius: 50px;
    padding: .5rem;
    background: darken(#c2d7cf, 5%);

    a {
      text-decoration: none;
      color: $color-dark;
      font-weight: 600;

      .flag-item {
        display: flex;
        align-items: center;
      }
    }
  }
}

@media (max-width: 1024px) {

  .login-container {
    width: 100%;
  }
}
.label {
  border-radius: 50px;

  &.label--circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;

    .mdi {
      font-size: 1.2rem;
      vertical-align: middle;
      line-height: 30px;
    }

  }

  &.label--s {
    padding: .2rem .4rem;
    font-size: .7rem;
  }

  &.label--m {
    padding: .4rem .8rem;
    font-size: 1rem;
  }

  &.label--l {
    padding: .6rem 1.6rem;
    font-size: 1.4rem;
  }

  &.label--menu {
    position: absolute;
    top: 10px;
    left: 5px;
    width: 20px;
    height: 20px;
    font-size: .8rem;
    font-weight: 800;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.label--secondary {
    background: $color-secondary;
    color: $color-white;
  }

  &.label--default {
    background: $color-black;
    color: $color-white;
  }

  &.label--gray {
    background: $color-gray;
    color: $color-white;
  }

  &.label--danger {
    background: $color-danger;
    color: $color-white;
  }

  &.label--success {
    background: $color-success;
    color: $color-white;
  }
}

.label__desc {
  margin-top: 2px;
  font-size: .7rem;

  &.success {
    color: $color-success;
  }

  &.danger {
    color: $color-danger;
  }

  &.default {
    color: $color-black;
  }

  &.gray {
    color: $color-gray;
  }

  &.secondary {
    color: $color-secondary;
  }

}
hr {
  border: none;
  border-bottom: 1px solid $color-bright;
}

.d--none {
  display: none !important;
}

.d--block {
  display: block;
}

.d--iblock {
  display: inline-block;
}

.d--flex {
  display: flex !important;
}

.d--iflex {
  display: inline-flex;
}

.d--grid {
  display: grid;
}

.fd--c {
  flex-direction: column;
}

.jc--fe {
  justify-content: flex-end;
}

.jc--fs {
  justify-content: flex-start;
}

.jc--sb {
  justify-content: space-between;
}

.jc--sa {
  justify-content: space-around;
}

.jc--c {
  justify-content: center;
}

.fw--w {
  flex-wrap: wrap;
}

.ai--fs {
  align-items: flex-start;
}

.ai--fe {
  align-items: flex-end;
}

.ai--c {
  align-items: center;
}

.ai--s {
  align-items: stretch;
}

.as--fs {
  align-self: flex-start;
}

.as--c {
  align-self: center;
}

.mt--0 {
  margin-top: 0;
}

.mt--1 {
  margin-top: .5rem;
}

.mt--2 {
  margin-top: 1rem;
}

.mt--3 {
  margin-top: 2rem;
}

.mt--4 {
  margin-top: 5rem;
}

.mb--1 {
  margin-bottom: .5rem;
}

.mb--2 {
  margin-bottom: 1rem;
}

.mb--3 {
  margin-bottom: 2rem;
}

.mb--4 {
  margin-bottom: 5rem;
}

.ml--1 {
  margin-left: .5rem !important;
}

.ml--2 {
  margin-left: 1rem !important;
}

.ml--3 {
  margin-left: 2rem;
}

.mr--0 {
  margin-right: 0 !important;
}

.mr--1 {
  margin-right: .5rem;
}

.mr--2 {
  margin-right: 1rem;
}

.mr--3 {
  margin-right: 2rem;
}

.mr--4 {
  margin-right: 5rem;
}

.m--1 {
  margin: .5rem;
}

.m--2 {
  margin: 1rem;
}

.m--3 {
  margin: 2rem;
}

.m--a0 {
  margin: auto 0;
}

.m--0a {
  margin: 0 auto;
}

.m--0aa {
  margin: 0 auto auto auto;
}

.m--aa0a {
  margin: auto auto 0 auto;
}

.m--a {
  margin: auto;
}

.ml--0 {
  margin-left: 0;
}

.ml--4 {
  margin-left: 5rem;
}

.p--0 {
  padding: 0 !important;
}

.pl--0 {
  padding-left: 0 !important;
}

.pl--2 {
  padding-left: 1rem;
}

.pl--3 {
  padding-left: 2rem;
}

.pl--10 {
  padding-left: 10rem !important;
}

.pr--4 {
  padding-right: 3rem !important;
}

.pb--0 {
  padding-bottom: 0 !important;
}

.pb--1 {
  padding-bottom: .5rem !important;
}

.pb--2 {
  padding-bottom: 1rem !important;
}

.pb--3 {
  padding-bottom: 2rem !important;
}

.pb--4 {
  padding-bottom: 8rem !important;
}

.pt--0 {
  padding-top: 0 !important;
}

.pt--4 {
  padding-top: 8rem !important;
}

.pt--5 {
  padding-top: 20rem;
}

.text--center {
  text-align: center;
}

.text--left {
  text-align: left;
}

.text--right {
  text-align: right;
}

.text--black {
  color: $color-black !important;
}

.text--dark {
  color: $color-dark !important;
}

.text--gray {
  color: $color-gray;
}

.text--warning {
  color: $orange-500;
}

.text--danger {
  color: $red-500;
}

.text--success {
  color: $green-400;
}

.text--light {
  color: $color-bright !important;
}

.text--featured {
  color: $color-secondary;
}

.text--brown {
  color: $color-brown;
}

.text--underline {
  text-decoration: underline;
}

.text--strong {
  font-weight: 800;
}

.heading--xl {
  font-size: 6rem;
  line-height: 6rem;
}

.heading--l {
  font-size: 5rem;
  line-height: 5rem;
}

.heading--m {
  font-size: 3rem;
  line-height: 3rem;
}

.text--xl {
  font-size: 2.6rem;
}

.text--l {
  font-size: 1.8rem;
}

.text--m {
  font-size: 1.2rem !important;
}

.text--s {
  font-size: .8rem;
  line-height: 1.2rem;
}

.text--xs {
  font-size: .8rem;
  display: inline-block;
  line-height: 1.2rem;
}

.mw--100 {
  max-width: 100%;
}

.w--100 {
  width: 100%;
}

.w--80 {
  width: 80%;
}

.w--70 {
  width: 70%;
}

.w--60 {
  width: 60%;
}

.w--50 {
  width: 50%;
}

.w--40 {
  width: 40%;
}

.w--33 {
  width: 33.3%;
}

.w--30 {
  width: 30%;
}

.h--a {
  height: auto;
}

.h--80 {
  height: 80%;
}

.h--90 {
  height: 90%;
}

.h--100 {
  height: 100%;
}

.mh--100 {
  max-height: 100%;
}

.o--hidden {
  overflow: hidden;
}

.img--bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.color--bg-full__white {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background: $white;
  z-index: -1;
}

.img--bg-full {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;

  &.over {
    z-index: 1 !important;
  }

  &.over--menu {
    z-index: 11 !important;
  }
}

.img--enter {
  width: 30px;
  height: auto;
}

.bg--featured {
  background: $color-secondary;
}

.bg--warning {
  background: $color-warning;
}

.bg--correct {
  background: $green-400;

  p, h2 {
    color: $white !important;
  }
}

.bg--incorrect {
  background: $red-400;

  p, h2 {
    color: $white !important;
  }
}

.p--rel {
  position: relative;
}

.disabled {
  opacity: .4;
  cursor: not-allowed;
}

.text-theory--table {
  font-weight: 700;
  opacity: .75;

  .text--xs {
    padding-bottom: 8px;
  }
}

.cs--theory {
  position: fixed;
  left: .5rem;
  top: 80px;
  background: rgba(0, 0, 0, .5);
  padding: .3rem .6rem;
  border-radius: 5px;
  margin: 0;
  color: $color-white;
  font-size: 1rem;
  z-index: 1;

  @media (max-width: 1024px) {
    display: none;
  }
}

.display--xs {
  display: none;
}

@media (max-width: 1024px) {

  .m\:w--100 {
    width: 100%;
  }

  .m\:h--a {
    height: auto;
  }

  .m\:fd--c {
    flex-direction: column;
  }

  .m\:fw--w {
    flex-wrap: wrap;
  }

  .m\:mb--2 {
    margin-bottom: 1rem;
  }

  .m\:ai--fs {
    align-items: flex-start;
  }

  .heading--xl {
    font-size: 3.5rem;
  }

  .heading--l {
    font-size: 2.5rem;
  }

  .heading--m {
    font-size: 2.2rem;
  }

  .text--xl {
    font-size: 1.6rem;
  }

  .m\:mb--1 {
    margin-bottom: 1rem;
  }

  .m\:mr--0 {
    margin-right: 0 !important;
  }

  .m\:d--none {
    display: none;
  }

  .m\:text--center {
    text-align: center;
  }
}

@media (max-width: 640px) {

  .hidden--xs {
    display: none;
  }

  .display--xs {
    display: block;
  }

  .text--l {
    font-size: 1.4rem;
  }

  .sm\:d--none {
    display: none;
  }

  .sm\:mr--0 {
    margin-right: 0;
  }

  .sm\:mr--1 {
    margin-right: .3rem;
  }

  .sm\:ml--0 {
    margin-left: 0;
  }

  .sm\:ml--1 {
    margin-left: .3rem;
  }

  .sm\:mb--1 {
    margin-bottom: .3rem;
  }

  .sm\:mb--2 {
    margin-bottom: 1rem;
  }

  .sm\:mt--1 {
    margin-top: .3rem;
  }

  .sm\:mt--2 {
    margin-top: 1rem;
  }

  .sm\:text--center {
    text-align: center;
  }

  .sm\:h--100 {
    height: 100%;
  }

  .m\:h--a {
    height: auto;
  }

  .sm\:w--a {
    width: auto;
  }

  .sm\:w--100 {
    width: 100%;
  }

  .sm\:w--30 {
    width: 30%;
  }

  .sm\:w--50 {
    width: 50%;
  }

  .sm\:w--40 {
    width: 40%;
  }

  .sm\:w--60 {
    width: 60%;
  }

  .sm\:w--70 {
    width: 70%;
  }

  .sm\:o--1 {
    order: 1;
  }

  .sm\:o--2 {
    order: 2;
  }

  .sm\:o--3 {
    order: 3;
  }

  .sm\:fd--c {
    flex-direction: column;
  }

  .sm\:fw--w {
    flex-wrap: wrap;
  }

  .sm\:jc--fs {
    justify-content: flex-start;
  }

  .sm\:jc--c {
    justify-content: center;
  }

  .sm\:ai--fs {
    align-items: flex-start;
  }
}

@media (min-width: 641px) and (max-width: 1024px) {

  .m\:jc--fs {
    justify-content: flex-start;
  }
}




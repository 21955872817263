.theory__sources {
  position: fixed;
  left: .5rem;
  bottom: .5rem;
  background: rgba(0, 0, 0, .5);
  padding: .3rem;
  border-radius: 5px;
  margin: 0;
  list-style: none;

  li {
    font-size: .8rem;

    a {
      color: $color-white;
      text-decoration: none;
    }
  }
}

.theory-container {
  background-size: cover;
  position: fixed;
  width: 100%;
  height: calc(100% - 65px);
  margin-top: 65px;

  .text-bubble-theory {
    position: absolute;
    background: $white;
    padding: 1.2rem;
    border-radius: 10px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, .2);
    z-index: 1;
    opacity: 0;
    transform: scale(0);
    animation-name: bubble-in;
    animation-duration: .02s;
    animation-fill-mode: forwards;

    p {
      font-size: 1rem;
      line-height: 1.4rem;
      font-weight: 500;
    }

    &:after {
      position: absolute;
      top: 30px;
    }

    &.left {

      &:after {
        content: '';
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: none;
        border-left: 10px solid white;
        right: -10px;
        top: 30px;
      }
    }

    &.right {

      &:after {
        content: '';
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: none;
        border-right: 10px solid white;
        left: -10px;
        top: 30px;
      }
    }
  }

  .theory__table-right {

    .dialog-container {
      right: 0;
    }
  }

  .marvin__img {
    height: calc(80vh - 80px);
    width: auto;

    &.intro {
      height: calc(60vh - 80px);
    }

    &.s {

      & + .text-bubble-theory {
        top: 150px !important;
      }
    }
  }

  .dialog__img {
    height: calc(80vh - 80px);
    width: auto;

    &.s {

      & + .text-bubble-theory {
        top: 150px !important;
      }
    }
  }

  .theory__book-container {
    position: relative;
    z-index: 1;

    .theory__book-book {
      position: relative;
      height: 80vh;

      &.empty {

        h2 {
          position: absolute;
          left: 12vh;
          top: 20vh;
          width: calc(50% - 12vh);
          font-size: 6vh;
          text-align: center;
          line-height: 10vh;
          font-family: $font-secondary;
          font-weight: 400;
          color: #16264C;
        }
      }

      &.full {

        .theory__book-content {
          position: absolute;
          right: 5vh;
          top: 10vh;
          width: calc(50% - 12vh);
          text-align: center;

          h2 {
            font-size: 5vh;
            line-height: 6vh;
            font-family: $font-secondary;
          }

          p {
            font-size: 3.2vh;
            line-height: 4vh;
            font-family: $font-secondary;
          }
        }
      }
    }
  }

  .theory__table {
    width: 550px;
    height: 50vh;
    position: absolute;
    bottom: 25vh;
    right: 0;
    background-image: linear-gradient(to bottom right, rgb(253, 255, 246), rgb(214, 216, 208));
    z-index: 1;
    border-radius: 10px;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, .2);
    overflow: hidden;
    padding: 65px 1rem 45px 1rem;

    &:after {
      content: '';
      height: 10px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      background: #ccc;
    }

    &:before {
      content: '';
      height: 6px;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: #555;
    }

    .table__top {
      padding: 10px 0 5px 0;
      border-bottom: 2px solid $color-dark;
      position: absolute;
      top: 0;
      left: 1rem;
      bottom: 0;
      width: calc(100% - 2rem);
      height: max-content;

      img {
        height: 42px;
      }
    }

    .table__bottom {
      position: absolute;
      left: 1rem;
      bottom: 0;
      width: calc(100% - 2rem);
      padding: 10px 0 20px 0;
      border-top: 2px solid $color-dark;

      p {
        font-size: .8rem !important;
        line-height: .8rem !important;
        color: $blue;
        font-weight: 700;
      }
    }

    p {
      font-size: 2.1vh;
      line-height: 2.6vh
    }
  }

  .marvin-bmc {
    display: flex;

    .marvin-container {
      position: absolute;
      bottom: -280px;
      z-index: 1;
      left: 48%;
    }

    .text-bubble-theory {
      width: 300px;
      max-height: 450px;

      &.left {
        right: 100%;
        top: 0;
      }

      &.right {
        left: 100%;
        top: 0;
      }
    }
  }

  .marvin {
    display: flex;

    &.full {

      .marvin-container {
        left: 0;
        width: 100%;
        text-align: center;
      }
    }

    .marvin-container {
      position: absolute;
      bottom: -80px;
      z-index: 1;
    }

    .text-bubble-theory {
      width: 300px;
      z-index: 1;
      max-height: 450px;

      &.left {
        right: 100%;
        top: 0;
      }

      &.right {
        left: 100%;
        top: 0;
      }

      &.top {
        left: calc(50% - 250px);
        top: -150px;
        width: 500px;
        max-height: 150px;
      }
    }
  }

  .dialog {
    display: flex;

    .marvin-container {
      position: absolute;
      bottom: -80px;
      left: 0;
      z-index: 1;
    }

    .text-bubble-theory {
      width: 300px;
      z-index: 1;
      max-height: 450px;

      &.right {
        left: 100%;
        top: 0;
      }

      &.left {
        right: 100%;
      }

    }

    .dialog-container {
      position: absolute;
      bottom: -80px;
      right: 0;
      z-index: 1;
    }
  }

  .rows2 {
    display: flex;

    .row2__img-container {
      padding: 1rem;
      height: 40vh;
      background: $white;
      border-radius: 10px;
      box-shadow: 5px 5px 15px rgba(0, 0, 0, .1);

      img {
        height: 100%;
      }
    }

    .row2__text-container {

      p {
        font-size: 2vh;
        line-height: 3.4vh;
      }
    }
  }

  .swot {

    .swot__left {
      position: fixed;
      left: 0;
      top: 0;
      width: 40%;
      height: 100vh;
    }

    .swot__right {
      margin-left: calc(40% + 3rem);
      height: calc(100% - 65px);
      display: flex;

      ul {
        margin: auto 0;
        list-style-type: circle;

        li {
          font-size: 3vh;
          margin-bottom: 2rem;
          font-weight: 600;
        }
      }
    }

    .swot__inner {
      margin: auto;
      text-align: center;

      h2 {
        font-size: 30vh;
        position: relative;
        margin-bottom: 100px;
        color: $black;

        &:after {
          content: "";
          position: absolute;
          left: 25%;
          bottom: -20px;
          height: 2px;
          width: 50%;
          background: $black;
        }
      }

      p {
        font-size: 7vh;
        line-height: 8vh;
        text-transform: uppercase;
        color: $black;
      }
    }

    .yellow {
      background: yellow;
      height: 100%;
      display: flex;
    }

    .orange {
      background: orange;
      height: 100%;
      display: flex;
    }

    .purple {
      background: #9973a8;
      height: 100%;
      display: flex;
    }

    .lightblue {
      background: lightblue;
      height: 100%;
      display: flex;
    }
  }

  .marvin-dialog-table {
    display: flex;

    .marvin-container {
      position: absolute;
      bottom: -80px;
      z-index: 1;

      .marvin__img {

      }
    }

    .text-bubble-theory {
      width: 300px;
      z-index: 1;
      max-height: 450px;

      &.right {
        right: 0;
        bottom: 100%;
        top: auto;

        &:after {
          content: '';
          width: 0;
          height: 0;
          border-top: 10px solid white;
          border-bottom: none;
          border-right: 10px solid transparent;
          border-left: 10px solid transparent;
          right: 20px;
          left: auto;
          top: 100%;
        }
      }

      &.left {
        left: 0;
        bottom: 100%;
        top: auto;

        &:after {
          content: '';
          width: 0;
          height: 0;
          border-top: 10px solid white;
          border-bottom: none;
          border-right: 10px solid transparent;
          border-left: 10px solid transparent;
          left: 20px;
          top: 100%;
        }
      }
    }

    .dialog-container {
      position: absolute;
      bottom: -80px;
      z-index: 1;
    }

    .theory__table {
      width: 100%;
      position: absolute;
      z-index: 2;

      .table__img {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
      }

      .table-bottom {
        width: 160px;
        height: 140px;
        position: absolute;
        bottom: -150px;
        left: calc(50% - 80px);
        z-index: 1;
      }

      p {
        font-size: 1.6rem;
        line-height: 2.4rem;
      }
    }

  }

  .marvin-table {
    display: flex;
  }

  .marvin-table-column {
    display: flex;

    .table__img {
      max-width: 100%;
      max-height: 100%;
      margin: auto;
    }

    .marvin-container {
      position: absolute;
      bottom: -80px;
      left: 0;
      z-index: 1;
    }

    .text-bubble-theory {
      width: 300px;
      z-index: 1;
      max-height: 450px;

      &.right {
        left: 100%;
        top: 0;
      }
    }
  }
}

@media (max-width: 1024px) {

  .theory-container {
    height: calc(100vh - 45px);
    margin-top: 45px;
    padding: 0;

    .theory__table {
      width: calc(100% - 10px);
      padding: 45px .5rem 30px .5rem;

      &:after, &:before {
        height: 3px;
      }

      .table__top {
        padding: 7px 0 5px 0;

        img {
          height: 25px;
        }
      }

      .table__bottom {
        position: absolute;
        left: 10px;
        bottom: 0;
        width: calc(100% - 20px);
        padding: 5px 0 7px 0;
        border-top: 1px solid $color-dark;

        p {
          font-size: .6rem !important;
          line-height: .6rem !important;
        }
      }

      p {
        font-size: 1.8vh;
        line-height: 2.4vh;
      }
    }

    .dialog-container, .marvin-container {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .marvin {

      .text-bubble-theory {

        &.left {

          &:after {
            left: 50% !important;
          }
        }

        &.right {
          left: 1.5rem;
          bottom: 100%;
          top: auto;

          &:after {
            content: '';
            width: 0;
            height: 0;
            border-top: 10px solid white;
            border-bottom: none;
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
            left: 20px;
            top: 100%;
          }
        }
      }
    }

    .dialog, .marvin-table-column, .marvin-dialog-table, .marvin {

      .text-bubble-theory {
        width: calc(100% - 3rem);
        z-index: 1;
        text-align: justify;
        padding: .5rem;

        p {
          font-size: .7rem;
          line-height: .9rem;
        }

        &.left {
          right: 1.5rem;
          bottom: calc(100% + 2rem);
          top: auto;

          &:after {
            content: '';
            width: 0;
            height: 0;
            border-top: 10px solid white;
            border-bottom: none;
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
            left: 50%;
            top: 100%;
          }
        }

        &.right {
          left: 2rem;
          bottom: calc(100% + 2rem);
          top: auto;

          &:after {
            content: '';
            width: 0;
            height: 0;
            border-top: 10px solid white;
            border-bottom: none;
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
            left: 50%;
            top: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 640px) {

  .theory-container {

    .marvin__img, .dialog__img {
      height: 350px;
      margin-left: calc(50% - 80px);

      &.s {
        height: 275px;
        width: auto;
        max-width: none;
      }
    }

    .text-bubble-theory {

      &.left, &.right {
        right: 0 !important;
        top: auto !important;
        left: 0 !important;
        width: calc(100% - 1rem) !important;
        bottom: calc(100% + 15px) !important;
        padding: .5rem;
        margin: 0 .5rem;

        p {
          font-size: .7rem;
          line-height: .9rem;
        }

        &:after {
          content: '';
          width: 0;
          height: 0;
          border-top: 10px solid white;
          border-bottom: none;
          border-right: 10px solid transparent;
          border-left: 10px solid transparent;
          left: calc(50% - 5px);
          top: 100%;
        }
      }
    }

    .sm\:theory__table-left {

      .marvin-container, .dialog-container {
        left: auto;
        right: 0px;
        width: 100%;
        bottom: -130px;
      }
    }

    .sm\:theory__table-right {

      .marvin-container, .dialog-container {
        left: 0px;
        right: auto;
        width: 100%;
        bottom: -130px;
      }
    }

    .sm\:theory__table-center {

      .marvin-container {
        bottom: -130px;
      }
    }

    .sm\:theory__table-fixed {
      height: auto !important;
      position: fixed;
      top: 55px;
      bottom: auto;
      left: 10px;
      width: calc(100% - 20px);
      max-height: 350px;
      min-height: 200px;

      .theory__table {
        width: 100%;
        height: 100%;
        bottom: auto;
        padding: 45px .5rem 30px .5rem;

        &:after, &:before {
          height: 3px;
        }

        .table__top {
          padding: 7px 0 5px 0;
          border-bottom: 1px solid $color-dark;
          position: absolute;
          top: 0;
          left: 10px;
          bottom: unset !important;
          width: calc(100% - 20px);
          height: max-content;

          img {
            height: 25px;
          }
        }

        .table__bottom {
          position: absolute;
          left: 10px;
          bottom: 0;
          width: calc(100% - 20px);
          padding: 5px 0 7px 0;
          border-top: 1px solid $color-dark;

          p {
            font-size: .6rem !important;
            line-height: .6rem !important;
          }
        }
      }

      p {
        font-size: .7rem;
        line-height: .9rem;
      }
    }

    .sm\:theory__table-center {
      margin-left: 0;
    }

    .marvin-table, .marvin-table-column {

      .marvin-container {
        left: auto;

        .marvin__img {
          position: relative;
        }
      }
    }

    .dialog {

    }

    .marvin-dialog-table {

    }

    .theory__book-container {

      .theory__book-book {

        img {
          width: 100%;
          height: auto;
          margin-top: 10px;

        }

        &.empty {

          h2 {
            left: 8vw;
            width: calc(50% - 8vw);
            font-size: 20px;
            line-height: 30px;
            top: 100px;
          }
        }

        &.full {

          .theory__book-content {
            right: 4vw;
            top: 40px;
            width: calc(50% - 8vw);

            h2 {
              font-size: 18px;
              line-height: 24px;
            }

            p {
              font-size: 11px;
              line-height: 14px;
            }
          }
        }
      }
    }
  }

  .swot {

    .swot__left {
      width: 100% !important;
      height: 35vh !important;
      margin-top: 65px;
    }

    .swot__right {
      margin-left: 0 !important;
      height: auto !important;
      margin-top: 50%;

      ul {
        padding-left: 30px;

        li {
          font-size: 2.6vh !important;
          margin-bottom: 1rem !important;
        }
      }
    }

    .swot__inner {

      h2 {
        font-size: 14vh !important;
        margin-bottom: 30px !important;

        &:after {
          bottom: -10px !important;
        }
      }

      p {
        font-size: 4vh !important;
      }
    }
  }
}

@keyframes bubble-in {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
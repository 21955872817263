.progress-container {
  border: 1px solid $gray-200;
  border-radius: 10px;

  .progress {

    &.vertical {
      height: 100%;
      background: $color-white;
      width: 10px;
      border-radius: 10px;
      position: relative;

      .progress__fill {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-radius: 10px;
      }
    }

    &.horizontal {
      width: 100%;
      background: $color-white;
      height: 8px;
      border-radius: 8px;
      position: relative;

      .progress__fill {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 20%;
        border-radius: 10px;
      }
    }

    &.success {
      background: $color-white;

      .progress__fill {
        background: $color-secondary;
      }
    }

    &.warning {
      background: $color-white;

      .progress__fill {
        background: $orange-500;
      }
    }

    &.danger {
      background: $color-white;

      .progress__fill {
        background: $red-500;
      }
    }
  }
}
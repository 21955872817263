.open-task {
  margin-left: 10%;
}

@media (max-width: 640px) {

  .open-task {
    margin-left: 0;

    h3 {
      font-size: .9rem;
    }

    p {
      font-size: .8rem;
      line-height: 1rem;
    }
  }
}
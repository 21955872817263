.market-container {
  position: fixed;
  top: 150px;
  right: -580px;
  width: 650px;
  height: 600px;
  background: $white;
  border-radius: 20px 0 0 20px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, .3);
  transition: all .2s;
  padding: 2rem;
  overflow: hidden;
  z-index: 1;

  .market__modal {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: rgba(20, 20, 20, .7);

    .market__modal-inner {

      h2 {
        font-size: 2.4rem;
        color: $white;
      }

      p {
        font-size: 1.4rem;
        font-weight: 500;
        color: $light;
      }

      .btn {
        color: $white;
      }

      .btn--cancel {
        background: $gray-500;
      }

      .btn--submit {
        background: $color-secondary;
      }

      button.button-clear {
        position: absolute;
        top: 20px;
        right: 20px;
        color: $white;

        span {
          font-size: 3rem;
        }
      }
    }
  }

  &.active {
    right: 0px;
  }

  .market__left {
    border-right: 1px solid $gray-200;
    position: relative;
    width: 25%;

    h2 {
      position: absolute;
      left: -10px;
      bottom: -30px;
      transform-origin: 0 0;
      transform: rotate(-90deg);
      text-transform: uppercase;
      font-weight: 300;

      img {
        width: 20px;
      }
    }

    button {

      &.close {
        position: absolute;
        left: -7px;
        top: -7px;
        font-size: 2rem;
        color: $bright;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        transition: all .2s;
      }

      &.right {

        .mdi--left {
          display: none;
        }
      }

      &.left {

        .mdi--right {
          display: none;
        }
      }
    }


    .market__categories {
      margin: 0 50px;

      .market__category {
        height: calc(100% / 6);
        width: 47px;

        svg {
          fill: $dark;
          height: 45px;
          width: auto;
        }

        &.active {

          svg {
            fill: $color-secondary;
            stroke: $color-secondary;
          }
        }

        &:hover {

          svg {
            opacity: .8;
          }
        }
      }
    }
  }

  .market__right {
    padding: 0 0 0 2rem;
    width: 75%;
    flex-basis: auto;

    .market__category-name {
      font-size: 1.2rem;
      font-weight: 500;
    }

    .market-items {
      height: 380px;
      flex-direction: row;
      flex-wrap: wrap;

      .market__item {
        border-radius: 15px;
        position: relative;
        overflow: hidden;
        padding: 1rem;
        height: calc(50% - .5rem);
        width: calc(50% - .5rem);
        margin-right: 1rem;
        margin-bottom: 1rem;
        background: $gray-100;

        &:nth-of-type(2n) {
          margin-right: 0;
        }

        &:nth-of-type(3) {
          margin-bottom: 0;
        }

        &:nth-of-type(4) {
          margin-bottom: 0;
        }

        .item__inner {
          overflow: hidden;
          width: 100%;

          span {
            position: absolute;
            right: 15px;
            top: 15px;
            color: $light;
            font-size: 1.2rem;
            z-index: 1;
          }
        }

        .item__cost {
          font-size: 1.2rem;
          font-weight: 700;
          position: absolute;
          bottom: 10px;
          left: calc(50% - 10px);
        }

        img {
          width: auto;
          height: 100px;
        }

        &.active {

        }

        &.disabled {

          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .6);
          }

        }

        .item__buttons {
          position: absolute;
          bottom: 5px;
          left: 5px;
          width: calc(100% - 10px);

          button {
            height: 20px;
            padding: 0 1rem;
            min-width: 80px;
            border-radius: 10px;
            box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
            font-size: .8rem;

            &:hover {
              box-shadow: none;
            }
          }
        }
      }
    }

  }
}

@media (min-width: 1025px) and (max-width: 1919px) {

  .market-container {
    top: 70px;
    bottom: 10px;
    max-height: 600px;
    height: unset;
  }
}


@media (max-width: 1024px) {

  .market-container {
    position: fixed;
    top: 70px;
    right: calc(-100% + 65px);
    width: calc(100% - 15px);
    height: calc(100vh - 75px);
    padding: 1.3rem 1rem 1.3rem 1.3rem;
    z-index: 999;

    .market__left {
      width: 30%;

      h2 {
        font-weight: 400;
        font-size: 1.4rem;
      }

      .market__categories {
        margin: 0 40px;

        .market__category {
          height: calc(100% / 6);

          svg {
            height: 25px;
          }

          &.active {

            svg {
              fill: $color-secondary;
            }
          }

          &:hover {

            svg {
              opacity: .8;
            }
          }
        }
      }
    }

    .market__right {
      padding: 0 0 0 1rem;
      width: 70%;

      .market-items {
        height: unset;

        .market__item {
          padding: .5rem;
          height: calc(50% - .25rem);
          width: calc(50% - .25rem);
          margin-right: .5rem;
          margin-bottom: .5rem;

          img {
            height: 50px;
          }

          .item__buttons {

            button {
              font-size: .5rem;

              &:hover {
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }
}
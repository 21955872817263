$mdi-font-path: '~@mdi/font/fonts/';

@import '~@mdi/font/scss/materialdesignicons';

@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

$font-primary: "Quicksand", sans-serif;
$font-secondary: 'Pacifico', sans-serif;
$color-primary: #283648;

$color-secondary: #5AD7EE;

$color-success: #00ad7c;
$color-danger: #ff304b;
$color-warning: #f7BB00;
$color-brown: #DED6BF;
$color-white: #fff;
$color-bright: #e6e6e6;
$color-gray: #bbb;
$color-dark: #777;
$color-black: #3a3a3a;
$color-brown: rgba(183,69, 29,1);

$white: #fff;
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66D9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$light: #ccc;
$bright: #989898;
$dark: #181e26;
$black: #000;

$pink-warning: #f26a5e;

$color-info: rgb(0, 161, 252);

$green-100: #F0FFF4;
$green-200: #C6F6D5;
$green-400: #68d391;
$green-500: #38a169;
$green-800: #22543D;

$gray-100: #F7FAFC;
$gray-200: #EDF2F7;
$gray-300: #E2E8F0;
$gray-400: #CBD5E0;
$gray-500: #A0AEC0;
$gray-600: #718096;
$gray-700: #3a4856;
$gray-800: #1A202C;

$red-100: #FFF5F5;
$red-200: #feb2b2;
$red-400: #FC8181;
$red-500: #ff0000;
$red-800: #742a2a;

$orange-100: #FFFFF0;
$orange-200: #FEEBC8;
$orange-300: #FAF089;
$orange-400: #F6E05E;
$orange-500: #ECC94B;
$orange-600: #D69E2E;
$orange-800: #744210;

$color-active: $color-secondary;
$color-correct: $green-500;
$color-incorrect: $red-500;

$dash-input-border: #eee;

$sidebar-bg: $color-bright;
$sidebar-border: #eee;

$incomplete-label: $pink-warning;